export const getErrorCode = (errorMessage) => {
  if (!errorMessage && typeof errorMessage !== 'string') {
    return {
      code: null,
      message: null,
      error: null,
    }
  }

  const [errorCode, ...message] = errorMessage.split(':')

  return {
    code: errorCode,
    message: message,
    error: errorMessage,
  }
}
