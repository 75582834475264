import includes from 'lodash/includes'
import find from 'lodash/find'
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { differenceInMinutes, parse } from 'date-fns'

import { PRODUCT_STATUS } from '@/constants/utils'
import { PRODUCT } from '@/utils'
import {
  calculateProductStatus,
  isLegacyApp,
  normalizeItemProductType,
  isEveryErrorInactiveProduct,
} from '@/utils/products'
import {
  DEFAULT_PROTOCOL_VERSION,
  PREMIUM_SALES_PLANS,
  PAYMENT,
  SALES_PLANS,
} from '@/constants'
import {
  getProductSalesPlan,
  getExpiredSubscriptions,
  getSubscriptionsWithInsufficientPermissions,
  getUnpurchaseableSubscriptions,
  checkIfUserNotAssignedToActiveProduct,
  isSubscriptionFetched,
} from '@/store/subscription/selectors'
import {
  isAuthorized,
  isUserInitialized,
  canManageProduct,
  canInstallApplications,
  isUserDataFetched,
  canBuy,
} from '@/store/user/selectors'
import { getInstalledApps, getItemById, getItemBySlug } from './typedSelectors'

export * from '@/store/subscription/selectors'
export * from '@/store/agents/selectors'
export * from '@/store/user/selectors'
export * from '@/store/typedSelectors'

export const getApp = (state, id) => {
  const items = state.apps.items || {}

  if (items[id]) {
    return items[id]
  }

  return find(items, (item) => item.slug === id) || {}
}

export const getAppRating = (state, id) => {
  const rates = state.feedback.rates.items || {}

  if (rates[id]) {
    return rates[id]
  }

  return null
}

export const isFetchingRatesList = (state) => {
  return state.feedback.rates.isFetching
}

export const isRatesListFetched = (state) => {
  return state.feedback.rates.isFetched
}

export const getProductFeedbackSummary = (state) => {
  return state.feedback.summary || {}
}

export const isFetchingFeedbackSummary = (state) => {
  return state.feedback.summary.isFetching
}

export const isFeedbackSummaryFetched = (state) => {
  return state.feedback.summary.isFetched
}

export const getProductReviews = (state) => {
  const { reviews, isFetched, isFetching } = getProductFeedbackSummary(state)

  if (!isFetched || isFetching) {
    return null
  }

  return reviews
}

export const getProductRatesHistogram = (state) => {
  const { histogram, isFetched, isFetching } = getProductFeedbackSummary(state)

  if (!isFetched || isFetching) {
    return null
  }

  return histogram
}

export const getPublishedAppBySlug = (state, slug) => {
  const items = state.publishedApps.items || {}
  return (
    normalizeItemProductType(find(items, (item) => item.slug === slug)) || {}
  )
}

export const getPublishedAppByName = (state, name) => {
  const items = state.publishedApps.items || {}
  return find(items, (item) => item.name === name) || {}
}

export const getPublishedApp = (state, id) => {
  const items = state.publishedApps.items || {}

  if (items[id]) {
    return items[id]
  } else {
    // This is an extra case for apps in review to be able to have the same functionality as published app
    // TODO: Refactor InstallButton component to smaller ones
    const itemInReview = getAppInReview(state, id)

    if (itemInReview) {
      return itemInReview
    } else {
      return {}
    }
  }
}

export const getAppInReview = (state, id) => {
  const items = inReviewApps(state)

  return items[id] || {}
}

export const isPrivateApp = (state, id) => {
  const apps = privateApps(state)

  return !!apps[id]
}

export const isPublishedAppsFetching = (state) => {
  return state.publishedApps.isFetching || false
}

export const isPublishedAppsFetched = (state) => {
  return state.publishedApps.hasInitialized || false
}

export const isInstalledAppsFetched = (state) => {
  return state.installedApps.hasInitialized || false
}

export const isInReviewAppsFetched = (state) => {
  return state.inReviewApps.hasInitialized || false
}

export const isPrivateAppsFetched = (state) => {
  return state.apps.hasInitialized || false
}

export const getExpertError = (state) => state.experts.error
export const getIsSendingExpertComment = (state) =>
  state.experts.isSendingComment
export const getIsSendingExpertCommentSuccess = (state) =>
  state.experts.sendingCommentSuccess

export const getIsSendingExpertMessage = (state) =>
  state.experts.isSendingMessage
export const getIsSendingExpertMessageSuccess = (state) =>
  state.experts.sendingMessageSuccess

export const isAppInstallable = (state, id) => {
  const app = getApp(state, id)
  const publishedApp = getPublishedApp(state, id)

  return !!publishedApp.installable || !!app.installable
}

export const hasExternalLink = (state, id) => {
  return isBotEngine(id)
    ? process.env.GATSBY_BOTENGINE_INSTALL_URL
    : hasDirectInstallUrl(state, id) || undefined
}

export const isAvailableInCurrentPlan = (state, id) => {
  const productDesignation = getProductDesignation(state, id)
  const isPremiumProduct = isPremiumApp(state, id)
  const isTeamPlanProduct = isTeamPlanApp(state, id)

  switch (true) {
    case isPremiumProduct:
      return (
        isPremiumProductLicense(state, productDesignation) || !isPremiumProduct
      )
    case isTeamPlanProduct:
      return (
        isTeamPlanProductLicense(state, productDesignation) ||
        !isTeamPlanProduct
      )
    default:
      return true
  }
}

const isNotHubSpot = (id) => id !== 'hubspot'
const isAppleBusinessChat = (slug) => slug === 'apple-business-chat'

export const hasInstallationAppTutorial = (state, id) => {
  const app = getPublishedApp(state, id).id
    ? getPublishedApp(state, id)
    : getApp(state, id)

  const { tutorialUrl, slug, payment } = app

  const hasWidgets = hasAppWidgets(state, id)
  const hasTheme = hasAppTheme(state, id)
  const isLegacyApplication = isLegacyApp(id)
  const isChatBot = isBotEngine(id)

  return (
    (tutorialUrl && isAppleBusinessChat(slug)) ||
    (tutorialUrl &&
      !isLegacyApplication &&
      !hasTheme &&
      !hasWidgets &&
      isNotHubSpot(id) &&
      !isChatBot &&
      !payment)
  )
}

export const isAppPublished = (state, id) => {
  const app = getApp(state, id)
  return PRODUCT_STATUS.PUBLISHED === calculateProductStatus(app)
}

export const isAppPrivate = (state, id) => {
  const app = getApp(state, id)
  return app.id && !isAppPublished(state, id)
}

export const isAppInReview = (state, id) => {
  const apps = inReviewApps(state)
  return !!apps[id]
}

export const getAppPayment = (state, id) => {
  const app = getPublishedApp(state, id)
  return app.payment
}

export const getProductDesignation = (state, id) => {
  const app = getPublishedApp(state, id)
  return app.product || PRODUCT.LiveChat
}

export const getItemClientId = (state, id) => {
  const item = getItemById(state, id)

  if (item && item.authorization) {
    return item.authorization.clientId
  }

  return null
}

export const getItemOnboarding = (state, id) => {
  const item = getItemById(state, id)

  return (item && item.elements && item.elements.onboarding) || null
}

export const getItemPayment = (state, id) => {
  return getAppPayment(state, id) || getServicePayment(state, id)
}

export const hasAppWidgets = (state, id) => {
  const app = getPublishedApp(state, id)
  const widgets = get(app, 'widgets', [])

  return !!widgets && widgets.length > 0
}

export const hasAppAuthorization = (state, id) => {
  const app = getPublishedApp(state, id).id
    ? getPublishedApp(state, id)
    : getApp(state, id)
  return app.authorization
}

export const hasDirectInstallUrl = (state, id) => {
  const app = getPublishedApp(state, id).id
    ? getPublishedApp(state, id)
    : getApp(state, id)

  return app?.directInstallUrl
}

export const getTrustedProductUrl = (state, id) => {
  const app = getPublishedApp(state, id).id
    ? getPublishedApp(state, id)
    : getApp(state, id)

  return app?.customProps?.trustedUrl
}

export const hasAppTheme = (state, id) => {
  const app = getPublishedApp(state, id)
  return app.theme
}

export const isAppInstalled = (state, id) => {
  const installedAppsIds = getInstalledAppsIds(state)
  return includes(installedAppsIds, id)
}

export const getReadyToBuyApps = (state, productIds) => {
  const installedApps = getInstalledApps(state)
  const cart = getCart(state)

  return productIds.filter((productId) => {
    const isInstalled = installedApps.find(({ id }) => productId === id)
    const isAlreadyInCart = cart.products.find(({ id }) => productId === id)
    const isPrivate = isPrivateApp(state, productId)
    const isPaid = getAppPayment(state, productId)
    const designation = getProductDesignation(state, productId)
    const isUserNotAssignedToProduct = checkIfUserNotAssignedToActiveProduct(
      state,
      designation
    )

    if (
      isInstalled ||
      isAlreadyInCart ||
      (isPrivate && isPaid) ||
      isUserNotAssignedToProduct
    ) {
      return false
    }

    return true
  })
}

export const isApplicationReady = (state) => {
  const isUserFetched = isUserInitialized(state)
  const isUserAuthorized = isAuthorized(state)
  const isPublishedAppsFetched = !isPublishedAppsFetching(state)
  const isCartFetched = state.cart.isInitialized

  return (
    isUserFetched &&
    (isUserAuthorized
      ? isInstalledAppsFetched(state) && isCartFetched
      : isPublishedAppsFetched)
  )
}

export const isPremiumApp = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps.isPremium === 'true'
}

export const isTeamPlanApp = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps.isTeamPlan === 'true'
}

export const isVerifiedApp = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps.verified === 'true'
}

export const isAiPoweredApp = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps.isAiPowered === 'true'
}

export const isInternalProduct = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps?.internalProduct === 'true'
}

export const getAlternativeSettingUrl = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps?.alternativeSettingUrl || null
}

export const canInstallProduct = (state, id) => {
  const isInternalApp = isInternalProduct(state, id)
  const canInstallApps = canInstallApplications(state)

  return isInternalApp || canInstallApps
}

export const isTeamPlanProductLicense = (state, product) => {
  const salesPlan = getProductSalesPlan(state, product)
  return (
    isAuthorized(state) &&
    includes([...PREMIUM_SALES_PLANS, SALES_PLANS.TEAM], salesPlan)
  )
}

export const isPremiumProductLicense = (state, product) => {
  const salesPlan = getProductSalesPlan(state, product)
  return isAuthorized(state) && includes(PREMIUM_SALES_PLANS, salesPlan)
}

export const getChargeByAppId = (state, app) => {
  const isDirectCharge = app.payment.frequency !== PAYMENT.FREQUENCY.MONTHLY

  if (isDirectCharge) {
    return state.directCharges.items.find((item) => item.appId === app.id)
  } else {
    return state.recurrentCharges.items.find((item) => item.appId === app.id)
  }
}

export const getChargeByChargeId = (state, frequency, chargeId) => {
  const isDirectCharge = frequency !== PAYMENT.FREQUENCY.MONTHLY

  if (isDirectCharge) {
    return state.directCharges.items.find((item) => item.id === chargeId)
  } else {
    return state.recurrentCharges.items.find((item) => item.id === chargeId)
  }
}

export const getCheckoutCharge = (state, slug) => {
  const item =
    getPaymentItem(state) || (slug && getItemBySlug(state, slug)) || null
  const isExternal = isExternalCharge(state)
  const chargeType = getChargeType(state)

  if (isExternal) {
    const chargeId = getPaymentCharge(state)?.id
    return getChargeByChargeId(state, chargeType, chargeId)
  }

  return (item && getChargeByAppId(state, item)) || {}
}

export const getPaymentCharge = (state) => state.payment.charge

export const getChargeTaxes = (state) => {
  const charge = getPaymentCharge(state)
  return charge?.taxes || {}
}

export const isChargeHasTaxes = (state) => {
  const charge = getPaymentCharge(state)
  return !!charge?.taxes?.tax_rate
}

export const getChargeMonths = (state) => {
  const charge = getPaymentCharge(state)
  return charge?.months || 1
}

export const getPaymentItem = (state) => state.payment.item
export const getPaymentItemDesignation = (state) =>
  getPaymentItem(state)?.product || PRODUCT.LiveChat
export const isExternalCharge = (state) => state.payment.isExternalCharge
export const isProcessableCharge = (state) => state.payment.isProcessable
export const getChargeType = (state) => state.payment.chargeType
export const isPaymentPending = (state) => state.payment.isPending
export const isPaymentProcessing = (state) => state.payment.isProcessing
export const getPaymentError = (state) => state.payment.error
export const hasPaymentError = (state) => state.payment.hasError

export const isBotEngine = (id) => id === process.env.GATSBY_BOTENGINE_APP_ID

export const getCampaigns = (state) => {
  return state.campaigns.items || []
}

export const isCampaignsFetching = (state) =>
  state.campaigns.isFetching || false

export const getDeveloperProfile = (state, id) => {
  return state.developerProfiles.items[id] || {}
}

export const getCustomProps = (state, id) => {
  const app = getPublishedApp(state, id)
  return app.customProps || {}
}

export const getService = (state, id) => {
  const items = state.services.items || {}

  return items[id] || {}
}

export const getServiceBySlug = (state, slug) => {
  const items = state.services.items || {}
  return find(items, (item) => item.slug === slug) || {}
}

export const getServiceByName = (state, name) => {
  const items = state.services.items || {}
  return find(items, (item) => item.name === name) || {}
}

export const getServicePayment = (state, id) => {
  const service = getService(state, id)

  return service.payment
}

export const publishedApps = (state) => state.publishedApps.items
export const getInstalledAppsIds = (state) => state.installedApps.items
export const privateApps = (state) => state.apps.items
export const inReviewApps = (state) => state.inReviewApps.items
export const getProtocolVersion = () => DEFAULT_PROTOCOL_VERSION

export const getAppLink = (state, id, isEmbeddedView) => {
  const app = getPublishedApp(state, id)

  if (!app || !Object.keys(app).length) return '/apps/'

  const isService = app.customProps && app.customProps.isService === 'true'

  return isService
    ? `/services/${app.slug}/`
    : `/${isEmbeddedView ? 'embedded-apps' : 'apps'}/${app.slug}/`
}

export const checkIfIsService = (state, id) => {
  const item = getPublishedApp(state, id)
  return item.customProps ? item.customProps.isService === 'true' : false
}

export const isMonthlyPaymentOnly = (state, id) => {
  const item = getPublishedApp(state, id)
  return item?.customProps?.monthlyPaymentOnly === 'true'
}

export const hasDirectInstallUrlInNewTab = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps && customProps.directInstallUrlInNewTab === 'true'
}

export const getFinishInstallUrl = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps?.finishInstallUrl
}

export const getFinishInstallCopy = (state, id) => {
  const customProps = getCustomProps(state, id)
  return customProps?.finishInstallCopy
}

export const hasInstallationProcessError = (state) => {
  return !!state?.installedApps?.error
}

export const getActiveCharge = (state) => {
  return state.payment.createdCharge
}

export const isUserReadyForCharge = (state) =>
  isUserDataFetched(state) &&
  isAuthorized(state) &&
  canBuy(state) &&
  isInstalledAppsFetched(state) &&
  isPublishedAppsFetched(state) &&
  isSubscriptionFetched(state) &&
  isCartInitialized(state)

export const isCardRequired = (state, id) => {
  const app = id ? getPublishedApp(state, id) : getPaymentItem(state)
  const payment = app?.payment

  if (!payment) {
    return true
  }

  return payment.creditCardRequired || payment.trialDays === 0
}

export const getCart = (state) => state.cart
export const isCartFetching = (state) => getCart(state).isFetching
export const isAddingProduct = (state, id) =>
  !!getCart(state)?.isAddingProduct[id]
export const isCartInitialized = (state) => getCart(state).isInitialized
export const getCartId = (state) => getCart(state)?.id
export const getCartSummary = (state) => getCart(state)?.summary
export const getCartProducts = (state) => getCart(state)?.products || []
export const getCartAgents = (state) => getCart(state)?.agents || []

export const getCartProductsLicenses = (state) => {
  const cartProducts = getCartProducts(state)
  const productLicenses = cartProducts?.reduce(
    (licenses, product) =>
      product?.productType
        ? [...licenses, product.productType]
        : [...licenses, PRODUCT.LiveChat],
    []
  )
  return [...new Set(productLicenses)]
}

export const getCartSummaryRecurrentPayments = (state) => {
  const recurrentPayments = getCartSummary(state)?.recurrent || []
  const unpurchaseableSubscriptions = getUnpurchaseableSubscriptions(state)

  return recurrentPayments?.filter(
    ({ productType }) => !unpurchaseableSubscriptions.includes(productType)
  )
}

export const getCartSummaryOneTimePayments = (state) => {
  const oneTimePayments = getCartSummary(state)?.oneTime || []
  const unpurchaseableSubscriptions = getUnpurchaseableSubscriptions(state)

  return oneTimePayments?.filter(
    ({ productType }) => !unpurchaseableSubscriptions.includes(productType)
  )
}

export const getCartProductsWithExpiredProduct = (state) => {
  const { oneTime, recurrent } = getCartSummary(state)
  const cartProducts = [...(oneTime || []), ...(recurrent || [])]
  const expiredProducts = getExpiredSubscriptions(state)

  return cartProducts?.filter(({ productType }) =>
    expiredProducts.includes(productType)
  )
}

export const getCartProductsWithInsufficientPermissions = (state) => {
  const { oneTime, recurrent } = getCartSummary(state)
  const cartProducts = [...(oneTime || []), ...(recurrent || [])]
  const inactiveLicenses = getSubscriptionsWithInsufficientPermissions(state)

  return cartProducts?.filter(({ productType }) =>
    inactiveLicenses.includes(productType)
  )
}

export const getCartProductsInactiveLicenses = (state) => {
  const cartProductsLicenses = getCartProductsLicenses(state)
  const activeLicenses = getActiveLicenses(state)

  const cartProductsInactiveLicenses = cartProductsLicenses?.filter(
    (license) => !activeLicenses?.includes(license)
  )

  return cartProductsInactiveLicenses || []
}

export const getActiveLicenses = (state) => {
  const agents = getCartAgents(state)
  const activeLicenses = agents?.map(({ product }) => product)

  return activeLicenses || []
}

export const isProductLicenseActive = (state, productType) => {
  if (!productType) {
    productType = PRODUCT.LiveChat
  }
  const activeLicenses = getActiveLicenses(state)

  return activeLicenses.includes(productType)
}

export const getCartAgentsCountForProduct = (state, productType) => {
  if (!productType) {
    productType = PRODUCT.LiveChat
  }

  const agents = getCart(state)?.agents
  return agents?.find(({ product }) => product === productType)?.agents
}

export const hasProductInCart = (state, productId) => {
  const cartProducts = getCartProducts(state)
  return cartProducts.some((product) => product.id === productId)
}

export const cartLockedUntilMins = (state) => {
  const cart = getCart(state)
  const { checkedOutUntil } = cart
  const today = new Date()

  if (checkedOutUntil) {
    const result = differenceInMinutes(
      parse(checkedOutUntil),
      parse(today.toISOString())
    )

    return result
  }

  return 0
}

export const getPaymentCart = (state) => state.payment?.cart
export const getPaymentCartId = (state) => getPaymentCart(state)?.id

export const getPaymentCartItems = (state) => {
  const paymentCart = getPaymentCart(state)
  const oneTime = paymentCart?.oneTime || []
  const recurrent = paymentCart?.recurrent || []
  return [...oneTime, ...recurrent]
}

export const getPublishedCartItems = (state) => {
  return getPaymentCartItems(state).map((item) =>
    getPublishedApp(state, item.id)
  )
}

export const getPurchasedItems = (state) => {
  const items = getPaymentCartItems(state)
  return items.filter((item) => item.purchasedAt)
}

export const getUnpurchasedItems = (state) => {
  const items = getPaymentCartItems(state)
  return items.filter((item) => !item.purchasedAt)
}

export const getCartItemsNotes = (state) => {
  const items = getUnpurchasedItems(state)

  return items.reduce((acc, item) => [...acc, item.note], [])
}

export const isEveryErrorInactiveLicense = (state) => {
  const notes = getCartItemsNotes(state)

  return isEveryErrorInactiveProduct(notes)
}

/**
 * Unfinished app installation - an app for which the developer requires additional configuration by the user, which is completed by opening provided `directInstallUrl`.
 * By default, after initial installation, that configuration is yet to be completed.
 * Finished app installation - an app which doesn't require additional configuration.
 */
export const getUnfinishedAppInstallations = (state) => {
  const items = getPaymentCartItems(state)

  const unfinishedAppInstallations = items
    ?.map(({ id }) => {
      const fullItemInfo = getItemById(state, id)

      if (fullItemInfo.directInstallUrl) {
        return id
      }
      return null
    })
    .filter(Boolean)

  return unfinishedAppInstallations || []
}

export const isNoCardRequiredCart = createSelector(
  [getPublishedCartItems],
  (publishedItems) => {
    const checkTrials = publishedItems.every(
      (item) => item.payment?.trialDays > 0
    )

    const checkCardRequired = publishedItems.every(
      (item) => item.payment?.creditCardRequired === false
    )

    return checkTrials && checkCardRequired
  }
)

export const getResources = (state) => state.resources.items || []
export const getAppResources = (state, id) =>
  getResources(state)?.filter((resource) => resource.application === id)

export const checkCustomPermissions = (state, id) => {
  const { onlyOwnerCanInstall, internalProduct } = getCustomProps(state, id)

  // Check is user can install internal product (HD, KB, etc.) and ones that require owner permissions
  if (
    (onlyOwnerCanInstall === 'true' || internalProduct === 'true') &&
    !canManageProduct(state)
  ) {
    return false
  }

  return true
}

export const getUtmParameters = (state) => state.stats.utm || {}
