import { ROLE } from '@/constants'
import { PRODUCT } from '@/utils'

export const getAgents = (state) => state.agents.items
export const isAgentsInitialized = (state) => state.agents.isInitialized

export const getOwner = (state, productType) => {
  const agents = getAgents(state)

  if (!productType) {
    productType = PRODUCT.LiveChat
  }

  return Object.values(agents).find((agent) => {
    return agent?.roles?.find(({ product, role }) => {
      const isProperProduct =
        product === productType || product === PRODUCT.Accounts
      const isOwner = role === ROLE.OWNER

      return isProperProduct && isOwner
    })
  })
}

export const getOwners = (state, productType) => {
  const agents = getAgents(state)

  if (!productType) {
    productType = PRODUCT.LiveChat
  }

  return Object.values(agents).filter((agent) => {
    return agent?.roles?.find(({ product, role }) => {
      const isProperProduct =
        product === productType || product === PRODUCT.Accounts
      const isOwner = role === ROLE.OWNER || role === ROLE.VICEOWNER

      return isProperProduct && isOwner
    })
  })
}

export const getAdmins = (state, productType) => {
  const agents = getAgents(state)

  if (!productType) {
    productType = PRODUCT.LiveChat
  }

  return Object.values(agents).filter((agent) => {
    return agent?.roles?.find(({ product, role }) => {
      const isProperProduct =
        product === productType || product === PRODUCT.Accounts
      const isAdmin =
        role === ROLE.OWNER ||
        role === ROLE.VICEOWNER ||
        role === ROLE.ADMINISTRATOR

      return isProperProduct && isAdmin
    })
  })
}
