import includes from 'lodash/includes'

const legacyApps = [
  'activecampaign',
  'analytics',
  'botengine',
  'campaignmonitor',
  'constantcontact',
  'deskcom',
  'dropbox',
  'dynamics',
  'freshbooks',
  'fullcontact',
  'getresponse',
  'icontact',
  'joinme',
  'kissmetrics',
  'logmein',
  'mixpanel',
  'nutshell',
  'salesforce',
  'salesforce2',
  'sugarcrm',
  'tactilecrm',
  'teamviewer',
  'whmcs',
]

const legacyAppsWithoutOldSettings = [
  'highrise',
  'joinme',
  'zendesk',
  'analytics',
  'dropbox',
  'kissmetrics',
  'mixpanel',
  'salesforce',
  'salesforce2',
  'teamviewer',
]

export const isLegacyApp = (appId) => {
  return includes(legacyApps, appId)
}

export const hasAppNewSettings = (appId) => {
  return includes(legacyAppsWithoutOldSettings, appId)
}
