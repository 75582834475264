import { put, call, takeEvery } from 'redux-saga/effects'

import { getAccountsList } from '@/apis/accounts/accounts'
import { fetchAgents } from '@/store/agents'
import { OK } from '@/constants/utils'

const normalizeRoles = (roles) => {
  if (!roles) {
    return []
  }

  return roles.map(({ role, product, role_id }) => ({
    id: role_id,
    product: String(product).toLowerCase(),
    role,
  }))
}

const normalizeAccountsData = (accounts) => {
  if (!accounts) {
    return {}
  }

  return accounts
    ?.filter((account) => account?.properties?.active)
    ?.reduce((prev, account) => {
      prev[account.account_id] = {
        account_id: account?.account_id,
        name: account?.name,
        email: account?.email,
        roles: normalizeRoles(account?.roles),
      }
      return prev
    }, {})
}

function* requestAgentList() {
  try {
    const response = yield call(getAccountsList)

    if (response?.status === OK) {
      yield put(
        fetchAgents.fulfilled({
          items: normalizeAccountsData(response?.json),
        })
      )
    } else {
      throw new Error(`Cannot fetch list of organization's accounts.`)
    }
  } catch (error) {
    yield put(
      fetchAgents.rejected({
        error: error?.message,
      })
    )
  }
}

export function* watchAgentsRequests() {
  yield takeEvery(fetchAgents.pending, requestAgentList)
}
