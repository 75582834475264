import { combineReducers } from 'redux'

import {
  reducerPath as accountsApiReducerPath,
  reducer as accountsApiReducer,
} from '@/apis/accounts/slice'
import {
  reducerPath as appsApiReducerPath,
  reducer as appsApiReducer,
} from '@/apis/apps/slice'
import {
  reducerPath as billingApiReducerPath,
  reducer as billingApiReducer,
} from '@/apis/billing/slice'

import { reducer as agents } from '@/store/agents'
import { reducer as experts } from '@/store/experts'
import { reducer as campaigns } from '@/store/campaigns'
import { reducer as subscription } from '@/store/subscription'
import { reducer as developerProfiles } from '@/store/developers'
import { reducer as services } from '@/store/services'
import { reducer as resources } from '@/store/resources'
import { reducer as apps } from '@/store/privateApps'
import { reducer as inReviewApps } from '@/store/inReviewApps'
import { reducer as installedApps } from '@/store/installedApps'
import { reducer as publishedApps } from '@/store/publishedApps'
import { reducer as feedback } from '@/store/feedback'
import { reducer as notifications } from '@/store/notifications'
import { reducer as user } from '@/store/user'
import { reducer as payment } from '@/store/payment'
import { reducer as cart } from '@/store/cart'
import { reducer as directCharges } from '@/store/directCharges'
import { reducer as recurrentCharges } from '@/store/recurrentCharges'
import { reducer as stats } from '@/store/stats'

const storeReducers = {
  user,
  subscription,
  apps,
  installedApps,
  publishedApps,
  inReviewApps,
  resources,
  feedback,
  campaigns,
  experts,
  services,
  directCharges,
  recurrentCharges,
  payment,
  cart,
  agents,
  developerProfiles,
  notifications,
  stats,
}

const apiReducers = {
  [accountsApiReducerPath]: accountsApiReducer,
  [appsApiReducerPath]: appsApiReducer,
  [billingApiReducerPath]: billingApiReducer,
}

const rootReducer = combineReducers({
  ...storeReducers,
  ...apiReducers,
})

export default rootReducer
