import { createAccountsApiRequest } from '@/utils/api'

export const getAccountsList = () =>
  createAccountsApiRequest({ path: `/v2/accounts` })

export const getAccount = (accountId: string) =>
  createAccountsApiRequest({
    path: `/v2/accounts/${accountId}`,
  })

export const getTokenInfo = () => createAccountsApiRequest({ path: `/v2/info` })
