import Cookies from 'js-cookie'

export const getUTMHeaders = () => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_name']

  return utmParams.reduce((headers, param) => {
    let utmValue = Cookies.get(param)

    if (utmValue) {
      const headerName = `flux-meta-${param.replace('_', '-')}`
      headers[headerName] = utmValue
    }

    return headers
  }, {})
}
