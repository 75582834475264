import { navigate } from 'gatsby'

import { FILTERS, DEFAULT_PRODUCT_HEADLINE, CATEGORY_LABELS } from '@/constants'
import { hasDuplicates } from '@/utils/func'
import {
  canUseWindow,
  convertObjectToParams,
  getUrlParams,
} from '@/utils/browser'
import { isHelpDesk, PRODUCT } from '@/utils/environment'
import { handleCompatibilityExclusion } from './filters'
import { sortAlphabetically } from './sorts'

export const checkIfAllProductsView = (rootPath = 'apps') => {
  if (canUseWindow) {
    const pathname = window.location.pathname
    const lastParam = pathname.split('/').filter(Boolean).pop()

    return lastParam === rootPath
  }

  return false
}

export const isOldCategoryView = (filterOptions) => {
  const supportedCategories = Object.values(filterOptions).map(
    ({ VALUE }) => VALUE
  )

  if (canUseWindow) {
    const lastParam = window.location.pathname.split('/').filter(Boolean).pop()

    return supportedCategories.includes(lastParam) ? lastParam : null
  }

  return false
}

export const getHeadline = (headline) => {
  switch (true) {
    case isHelpDesk:
      return DEFAULT_PRODUCT_HEADLINE[PRODUCT.HelpDesk]

    default:
      return headline
  }
}

export const getLabel = (name) => CATEGORY_LABELS[name] || name

export const handleCategorySelect = ({
  id = FILTERS.CATEGORY.ID,
  filterOptions = FILTERS.CATEGORY.OPTIONS,
  multiselect = true,
  value,
  sort,
  filters,
  updateFilters,
  rootPath = 'apps',
  hasSubPages = true,
}) => {
  const isAllAppsView = checkIfAllProductsView(rootPath)
  const categoriesFilter = filters?.[id]
  const isEmptyCategoriesFilter =
    !categoriesFilter ||
    (categoriesFilter &&
      Array.isArray(categoriesFilter) &&
      !categoriesFilter.length)

  let queryParams = convertObjectToParams({
    ...filters,
    sortBy: sort || '',
  })

  if (!isAllAppsView) {
    const oldCategoryParam = isOldCategoryView(filterOptions)
    const categoriesValues = [value]

    // Handling Session Storage exception with compatibility
    if (filters?.compatibility !== undefined) {
      handleCompatibilityExclusion(filters)
    }

    if (oldCategoryParam) {
      categoriesValues.push(oldCategoryParam)
    }

    if (hasSubPages && isEmptyCategoriesFilter) {
      navigate(`/${rootPath}/${value}/${queryParams}`)
    } else if (hasDuplicates(categoriesValues)) {
      const { categories, ...restFilters } = filters

      queryParams = convertObjectToParams({
        ...restFilters,
        sortBy: sort,
      })
      navigate(`/${rootPath}/${queryParams}`)
    } else {
      queryParams = convertObjectToParams({
        ...filters,
        categories: categoriesValues,
        sortBy: sort,
      })
      navigate(`/${rootPath}/${queryParams}`)
    }
  } else {
    if (hasSubPages && isEmptyCategoriesFilter) {
      // Handling Session Storage exception with compatibility
      handleCompatibilityExclusion(filters)

      navigate(`/${rootPath}/${value}/${queryParams}`)
    } else {
      updateFilters(id, value, multiselect)
    }
  }
}

export const getDefaultCategoriesFilters = () => {
  const { categories } = getUrlParams()

  if (categories) {
    return [categories]
  }

  return []
}

export const getDefaultCategoriesFiltersOptions = () => {
  const filterCategoriesOptions = Object.values(FILTERS.CATEGORY.OPTIONS).sort(
    sortAlphabetically('LABEL')
  )
  const filterWidgetPlacementOptions = Object.values(
    FILTERS.WIDGET_PLACEMENT.OPTIONS
  ).sort(sortAlphabetically('LABEL'))

  return {
    [FILTERS.CATEGORY.ID]: filterCategoriesOptions,
    [FILTERS.WIDGET_PLACEMENT.ID]: filterWidgetPlacementOptions,
  }
}
