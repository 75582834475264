export const convertToArray = (value) => {
  if (!value) {
    return []
  }

  return Array.isArray(value) ? value : [value]
}

export const hasDuplicates = (array) => {
  if (!Array.isArray(array) || !array) {
    return false
  }

  return new Set(array).size !== array.length
}
