import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'
import { normalizeItemProductType } from '@/utils/products'

const initialState = {
  isPending: false,
  isProcessing: false,
  hasError: false,
  error: null,
  item: null,
  charge: null,
  isExternalCharge: false,
  isProcessable: true,
  cart: null,
}

export const getCartInfo = createAsyncActions('payment/cart/getInfo')
export const purchaseCart = createAsyncActions('payment/cart/purchase')
export const startCartCheckout = createAsyncActions(
  'payment/cart/checkout/start'
)
export const rejectCartCheckout = createAsyncActions(
  'payment/cart/checkout/reject'
)
export const startPayment = createAsyncActions('payment/start')

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setCreatedChargeInfo(state, { payload }) {
      state.item = normalizeItemProductType(payload.item)
      state.charge = payload.charge
      state.chargeType = payload.chargeType
      state.isExternalCharge = payload.isExternalCharge
      state.isProcessable = payload.isProcessable
    },
    updateCreatedChargeInfo(state, { payload }) {
      state.charge = {
        ...state.charge,
        ...payload,
      }
    },
    clearPaymentInfo() {
      return initialState
    },
    directBuyNotLoggedIn() {},
    createCharge() {},
    acceptCharge() {},
    declineCharge() {},
    archiveCharge() {},
  },
  extraReducers: {
    [startPayment.pending]: (state) => {
      state.isPending = true
      state.isProcessing = true
      state.hasError = false
      state.error = null
    },
    [startPayment.fulfilled]: (state) => {
      state.isPending = false
      state.isProcessing = false
    },
    [startPayment.rejected]: (state, { payload }) => {
      state.isPending = false
      state.isProcessing = false
      state.error = payload?.error
      state.hasError = true
    },
    [purchaseCart.pending]: (state) => {
      state.isProcessing = true
      state.hasError = false
      state.error = null
    },
    [purchaseCart.fulfilled]: (state) => {
      state.isProcessing = false
    },
    [purchaseCart.rejected]: (state, { payload }) => {
      state.isProcessing = false
      state.error = payload?.error
      state.hasError = true
    },
    [getCartInfo.pending]: (state) => {
      state.isPending = true
      state.hasError = false
      state.error = null
    },
    [getCartInfo.fulfilled]: (state, { payload }) => {
      state.isPending = false
      state.cart = payload.cart
    },
    [getCartInfo.rejected]: (state, { payload }) => {
      state.isPending = false
      state.isProcessing = false
      state.error = payload?.error
      state.hasError = true
    },
  },
})

export const {
  setCreatedChargeInfo,
  updateCreatedChargeInfo,
  clearPaymentInfo,
  directBuyNotLoggedIn,
  createCharge,
  acceptCharge,
  declineCharge,
  archiveCharge,
} = paymentSlice.actions
export const reducer = paymentSlice.reducer
