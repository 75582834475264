import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseQuery } from '@/utils/api'
import type { IApplicationInstance, IDeveloperProfile } from './interfaces'

export const appsApi = createApi({
  reducerPath: 'appsApi',
  baseQuery: customFetchBaseQuery({
    baseUrl: process.env.GATSBY_DEV_PLATFORM_URL_V2,
  }),
  endpoints: ({ query }) => ({
    getInstance: query<IApplicationInstance, string>({
      query: (appId) => ({ path: `/applications/${appId}/instance` }),
    }),
    fetchDeveloperProfile: query<IDeveloperProfile, string>({
      query: (devId) => ({
        path: `/developers/${devId}/profile`,
        noauth: true,
      }),
    }),
  }),
})

export const {
  useGetInstanceQuery,
  useFetchDeveloperProfileQuery,
  reducer,
  reducerPath,
  middleware,
} = appsApi
