import React from 'react'
import {
  List,
  CreditCardOutline,
  Extension,
  Devices,
} from '@livechat/design-system-icons'

import CategoriesIcon from '@/assets/icons/categories.inline.svg'
import { FILTERS } from '@/constants'
import { displayCustomPaymentValue } from './filters'

export const getPaymentFilterConfig = (filters, handleSelect) => ({
  id: FILTERS.PAYMENT.ID,
  label: FILTERS.PAYMENT.LABEL,
  value: filters[FILTERS.PAYMENT.ID],
  options: Object.values(FILTERS.PAYMENT.OPTIONS),
  icon: <CreditCardOutline />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'paymentFiltersButton',
  displayCustomValue: displayCustomPaymentValue,
})

export const getCompatibilityFilterConfig = (filters, handleSelect) => ({
  id: FILTERS.COMPATIBILITY.ID,
  label: FILTERS.COMPATIBILITY.LABEL,
  value: filters[FILTERS.COMPATIBILITY.ID],
  options: Object.values(FILTERS.COMPATIBILITY.OPTIONS),
  icon: <Extension />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'compatibilityFiltersButton',
})

export const getWidgetPlacementFilterConfig = (filters, handleSelect) => ({
  id: FILTERS.WIDGET_PLACEMENT.ID,
  label: FILTERS.WIDGET_PLACEMENT.LABEL,
  value: filters[FILTERS.WIDGET_PLACEMENT.ID],
  options: Object.values(FILTERS.WIDGET_PLACEMENT.OPTIONS),
  icon: <Devices />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'widgetPlacementFiltersButton',
})

export const getCategoriesFilterConfig = (filters, handleSelect) => ({
  id: FILTERS.CATEGORY.ID,
  label: FILTERS.CATEGORY.LABEL,
  value: filters[FILTERS.CATEGORY.ID],
  options: Object.values(FILTERS.CATEGORY.OPTIONS),
  icon: <List />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'categoryFiltersButton',
})

export const getServicesFilterConfig = (filters, handleSelect, options) => ({
  id: FILTERS.SERVICE.ID,
  label: FILTERS.SERVICE.LABEL,
  value: filters[FILTERS.SERVICE.ID],
  icon: <List />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'servicesFiltersButton',
  options,
})

export const getLanguagesFilterConfig = (filters, handleSelect, options) => ({
  id: FILTERS.LANGUAGE.ID,
  label: FILTERS.LANGUAGE.LABEL,
  value: filters[FILTERS.LANGUAGE.ID],
  icon: <CategoriesIcon />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'languageFiltersButton',
  options,
})

export const getCountriesFilterConfig = (filters, handleSelect, options) => ({
  id: FILTERS.COUNTRY.ID,
  label: FILTERS.COUNTRY.LABEL,
  value: filters[FILTERS.COUNTRY.ID],
  icon: <CategoriesIcon />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'countryFiltersButton',
  options,
})

export const getIndustriesFilterConfig = (filters, handleSelect, options) => ({
  id: FILTERS.INDUSTRY.ID,
  label: FILTERS.INDUSTRY.LABEL,
  value: filters[FILTERS.INDUSTRY.ID],
  icon: <CategoriesIcon />,
  onSelect: handleSelect,
  multiselect: true,
  dataTest: 'industryFiltersButton',
  options,
})

export const getDefaultFiltersConfig = ({ filters, handleSelect }) => {
  return [
    getCompatibilityFilterConfig(filters, handleSelect),
    getPaymentFilterConfig(filters, handleSelect),
    getWidgetPlacementFilterConfig(filters, handleSelect),
  ]
}
