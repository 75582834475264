import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationsAdd(state, { payload }) {
      state.items.push(payload)
    },
    notificationsRemove(state, { payload }) {
      state.items.splice(
        state.items.findIndex((item) => item.id === payload.id),
        1
      )
    },
    notificationsCreate() {},
  },
})

export const {
  notificationsAdd,
  notificationsRemove,
  notificationsCreate,
} = notificationsSlice.actions
export const reducer = notificationsSlice.reducer
