import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  isInitialized: false,
  isAuthorized: false,
  error: null,
  permissions: [],
  data: {
    accountId: null,
    organizationId: null,
    licenseId: null,
    token: null,
    name: null,
    email: null,
    avatar: null,
  },
  isGhostLogin: false,
  isReseller: false,
}

export const userLogIn = createAsyncActions('user/logIn')
export const userLogOut = createAsyncActions('user/logOut')
export const checkIfUserIsReseller = createAsyncActions('user/checkIfReseller')

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [userLogIn.pending]: (_, { payload }) => {
      return {
        ...initialState,
        isFetching: true,
        data: {
          ...initialState.data,
          token: payload?.token,
        },
      }
    },
    [userLogIn.fulfilled]: (
      state,
      { payload: { permissions, isGhostLogin, ...data } }
    ) => {
      state.isFetching = false
      state.isAuthorized = true
      state.isInitialized = true
      state.data = data
      state.permissions = permissions
      state.isGhostLogin = isGhostLogin
    },
    [userLogIn.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isAuthorized = false
      state.isInitialized = true
      state.error = payload.error
    },
    [userLogOut.fulfilled]: () => {
      return { ...initialState, isInitialized: true }
    },
    [userLogOut.rejected]: (state, { payload }) => {
      state.error = payload.error
    },
    [checkIfUserIsReseller.fulfilled]: (state, { payload }) => {
      state.isReseller = payload.isReseller
    },
    [checkIfUserIsReseller.rejected]: (state) => {
      state.isReseller = false
    },
  },
})

export const reducer = userSlice.reducer
