import { createAccountsApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'

export const deleteConsent = (clientId: string, coverage = 'account') =>
  createAccountsApiRequest({
    method: HttpMethod.DELETE,
    path: `/v2/clients/${clientId}/consent`,
    body: {
      coverage,
    },
  })

export const checkConsent = (clientId: string, coverage = 'account') =>
  createAccountsApiRequest({
    method: HttpMethod.GET,
    path: `/v2/clients/${clientId}/consent/status?coverage=${coverage}`,
  })
