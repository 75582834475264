import { createBillingApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'
import { PRODUCT } from '@/utils'

import type { IGetPricePreview } from './interfaces'

const getPricePreview = ({
  price,
  clientId,
  productType = PRODUCT.LiveChat,
}: IGetPricePreview) => {
  const params = new URLSearchParams({
    price: `${price}`,
    order_client_id: clientId,
  })

  return createBillingApiRequest({
    method: HttpMethod.GET,
    path: `/price_preview/${productType}?${params}`,
  })
}

export default { getPricePreview }
