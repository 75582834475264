import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useImages } from '../hooks/useImages'

const Image = ({
  type,
  alt,
  imgStyles = {},
  wrapperStyles = {},
  ...imageProps
}) => {
  const images = useImages()
  const imgStyle = { objectFit: 'contain', ...imgStyles }
  const wrapperStyle = { ...wrapperStyles }
  const image = images[type]

  if (image) {
    return (
      <GatsbyImage
        alt={alt}
        style={wrapperStyle}
        imgStyle={imgStyle}
        image={image.childImageSharp.gatsbyImageData}
        {...imageProps}
      />
    )
  }

  return null
}

Image.propTypes = {
  type: PropTypes.string,
  alt: PropTypes.string,
  imgStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
}

export default Image
