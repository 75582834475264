import { call, put, takeEvery } from 'redux-saga/effects'
import { OK } from '@/constants/utils'

import {
  sendExpertVisitRequest,
  sendExpertComment,
  sendExpertMessage,
} from '@/store/experts'
import { notificationsCreate } from '@/store/notifications'
import { NOTIFICATIONS } from '@/constants'
import partnersApi from '@/apis/partners'

export function* requestExpertMessage({
  payload: {
    id,
    values: { email, userEmail, message, name, surname, website },
  },
}) {
  try {
    const { status } = yield call(partnersApi.sendExpertMessage, {
      id,
      email,
      userEmail,
      message,
      name,
      surname,
      website,
    })

    if (status === OK) {
      yield put(
        sendExpertMessage.fulfilled({
          id,
        })
      )
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.SUCCESS,
          content: 'Message sent successfully!',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      throw new Error("Expert request couldn't be sent")
    }
  } catch (error) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: "We couldn't send the expert request",
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    yield put(sendExpertMessage.rejected({ error: error?.message }))
  }
}

export function* requestExpertComment({
  payload: {
    id,
    values: { email, userEmail, review, name, website },
  },
}) {
  try {
    const { status } = yield call(partnersApi.sendExpertComment, {
      id,
      email,
      userEmail,
      review,
      name,
      website,
    })

    if (status === OK) {
      yield put(sendExpertComment.fulfilled({ id }))
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.SUCCESS,
          content: 'Review send to verify!',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else if (status === 409) {
      yield put(
        sendExpertComment.rejected({ error: 'Review has been already sent!' })
      )

      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.WARNING,
          content: 'Review has been already sent!',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      throw new Error("Expert review couldn't be sent")
    }
  } catch (error) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: "Expert review couldn't be sent",
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
    yield put(sendExpertComment.rejected({ error: error?.message }))
  }
}

export function* sendExpertVisit({ payload: { id } }) {
  try {
    yield call(partnersApi.sendExpertVisit, { id })
  } catch (err) {}
}

export function* watchExpertsRequests() {
  yield takeEvery(sendExpertMessage.pending, requestExpertMessage)
  yield takeEvery(sendExpertComment.pending, requestExpertComment)
  yield takeEvery(sendExpertVisitRequest, sendExpertVisit)
}
