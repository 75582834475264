import { takeEvery, put, call, spawn } from 'redux-saga/effects'

import { SENTRY, NOTIFICATIONS } from '@/constants'
import { OK, GET, POST } from '@/constants/utils'
import { fetchSubscription, requestAccessToProduct } from '@/store/subscription'
import { notificationsCreate } from '@/store/notifications'
import * as cart from '@/store/cart'
import { errorHandler, SagaError } from '@/utils/sagas'
import { createApiRequest, createAccountsApiRequest } from '@/utils/api'
import { getDesignationLabel } from '@/utils/products'

function* requestSubscription() {
  try {
    const subscriptionResponse = yield call(createApiRequest, {
      method: GET,
      path: `/market/products/my`,
    })

    if (subscriptionResponse?.status === OK) {
      yield put(
        fetchSubscription.fulfilled({
          products: subscriptionResponse.json,
        })
      )
    } else {
      throw new SagaError('Cannot fetch subscription info')
    }
  } catch (error) {
    yield spawn(errorHandler, error, SENTRY.TAGS.USER)
    yield put(fetchSubscription.rejected({ error: error?.message }))
  }
}

function* requestUserAccessToProduct({ payload: { designation } }) {
  try {
    const { status } = yield call(createAccountsApiRequest, {
      path: '/v2/accounts/me/send_request_for_invitation',
      method: POST,
      body: {
        product: getDesignationLabel(designation),
      },
    })

    if (status === OK) {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.SUCCESS,
          content: 'Request sent!',
          autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    } else {
      throw new Error('Cannot send access request.')
    }
  } catch (error) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: 'Cannot send access request.',
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
      })
    )
  }
}

export function* watchSubscriptionRequests() {
  yield takeEvery(fetchSubscription.pending, requestSubscription)
  yield takeEvery(requestAccessToProduct, requestUserAccessToProduct)

  // Refetch on every visit in the /cart
  yield takeEvery(cart.load.pending, requestSubscription)
}
