import { createAction } from '@reduxjs/toolkit'

interface IAsyncActions {
  pending: ReturnType<typeof createAction>
  fulfilled: ReturnType<typeof createAction>
  rejected: ReturnType<typeof createAction>
}

export const createAsyncActions = (type: string): IAsyncActions => ({
  pending: createAction(`${type}/pending`),
  fulfilled: createAction(`${type}/fulfilled`),
  rejected: createAction(`${type}/rejected`),
})
