import { canUseWindow } from './window'

if (canUseWindow) {
  import('blockadblock')
}

const initializeAdblockDetector = () => {
  const setAdblockEnabled = (status) => {
    if (canUseWindow) {
      window.__adblockEnabled = status
    }
  }

  setAdblockEnabled(false)

  // Function called if AdBlock is not detected
  function adBlockNotDetected() {
    setAdblockEnabled(false)
  }

  // Function called if AdBlock is detected
  function adBlockDetected() {
    setAdblockEnabled(true)
  }

  if (canUseWindow) {
    if (typeof window.blockAdBlock === 'undefined') {
      adBlockDetected()
    } else {
      window.blockAdBlock.onDetected(adBlockDetected)
      window.blockAdBlock.onNotDetected(adBlockNotDetected)
    }
  }
}

const isAdBlockEnabled = () => {
  if (canUseWindow && typeof window.__adblockEnabled !== undefined) {
    return window.__adblockEnabled
  }

  return false
}

export { initializeAdblockDetector, isAdBlockEnabled }
