import { PRODUCT, activeProduct } from '@/utils'
import { SUBSCRIPTION_EXPIRING_THRESHOLD } from '@/constants'
import {
  getCartProductsLicenses,
  getPaymentItem,
  getPaymentItemDesignation,
} from '@/store/selectors'

export const isSubscriptionFetched = (state) => state.subscription.isFetched
export const isSubscriptionFetching = (state) => state.subscription.isFetching
export const hasSubscriptionError = (state) => state.subscription.error

export const getProductSubscription = (state, product) => {
  if (!product) {
    product = PRODUCT.LiveChat
  }

  return state.subscription.products?.[product]
}

export const getProductCartSubscriptions = (state) => {
  const productCartLicenses = getCartProductsLicenses(state)

  return productCartLicenses?.map((license) =>
    getProductSubscription(state, license)
  )
}

export const getSubscriptionList = (state) => {
  return Object.keys(state.subscription.products)
}

export const hasCard = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.hasCard
}

export const hasSubscriptionWithFilledCard = (state) => {
  const paymentItem = getPaymentItem(state)
  const paymentItemDesignation = getPaymentItemDesignation(state)
  const productCartLicenses = getCartProductsLicenses(state)

  return paymentItem
    ? hasCard(state, paymentItemDesignation)
    : productCartLicenses?.some((license) => hasCard(state, license))
}

export const isActiveSubscription = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.active
}

export const isSubscriptionCanceled = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.cancelled
}

export const isSubscriptionAssigned = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.assigned
}

export const inTrial = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.trial
}

export const subscriptionTrialDaysLeft = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.daysLeft
}

export const getProductSalesPlan = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.plan
}

export const getCardDetails = (state, product) => {
  const subscription = getProductSubscription(state, product)
  return subscription?.cardDetails
}

export const getNumberOfTrialDays = (
  state,
  paymentDetails,
  isCardRequired,
  product
) => {
  let trialDays = 0

  if (!paymentDetails) {
    return trialDays
  }

  if (!isCardRequired) {
    trialDays = inTrial(state, product)
      ? paymentDetails.trialDays + subscriptionTrialDaysLeft(state, product)
      : paymentDetails.trialDays
  } else {
    trialDays = paymentDetails.trialDays
  }

  return trialDays
}

export const isProductSubscriptionValid = (state, product) => {
  const hasSubscriptionCard = hasCard(state, product)
  const isActive = isActiveSubscription(state, product)

  return hasSubscriptionCard && isActive
}

export const isSubscriptionExpiring = (state, product) => {
  const isInTrial = inTrial(state, product)
  const isCanceled = isSubscriptionCanceled(state, product)
  const daysLeft = subscriptionTrialDaysLeft(state, product)

  return (isInTrial || isCanceled) && daysLeft < SUBSCRIPTION_EXPIRING_THRESHOLD
}

export const getValidSubscriptions = (state) => {
  const subscriptionList = getSubscriptionList(state)
  const validSubscriptions = subscriptionList.filter((product) =>
    isProductSubscriptionValid(state, product)
  )

  return validSubscriptions || []
}

export const getInvalidSubscriptionsForCart = (state) => {
  // We're allowing users to go to checkout view even if they don't have permissions (or not exists in products) to buy all products.
  const insufficientPermissionsSubscriptions =
    getSubscriptionsWithInsufficientPermissions(state)
  const cartProductsLicenses = getCartProductsLicenses(state)
  const validSubscriptions = [
    ...new Set([
      ...getValidSubscriptions(state),
      ...insufficientPermissionsSubscriptions,
    ]),
  ]

  const invalidCartSubscriptions = cartProductsLicenses.filter(
    (license) => !validSubscriptions?.includes(license)
  )

  return invalidCartSubscriptions || []
}

export const checkIfUserNotAssignedToActiveProduct = (state, product) =>
  isSubscriptionAssigned(state, product) &&
  getProductSalesPlan(state, product) === '' &&
  !isActiveSubscription(state, product)

export const getSubscriptionsWithInsufficientPermissions = (state) => {
  const subscriptionList = getSubscriptionList(state)
  const insufficientPermissionsSubscriptions = subscriptionList.filter(
    (product) => checkIfUserNotAssignedToActiveProduct(state, product)
  )

  return insufficientPermissionsSubscriptions || []
}

export const isExpiredSubscription = (state, product) => {
  const isCanceled = isSubscriptionCanceled(state, product)
  const isActive = isActiveSubscription(state, product)
  const isAssigned = isSubscriptionAssigned(state, product)
  const salesPlan = getProductSalesPlan(state, product)

  return (
    (isAssigned && !isActive && salesPlan !== '') || (isAssigned && isCanceled)
  )
}

export const getExpiredSubscriptions = (state) => {
  const subscriptionList = getSubscriptionList(state)
  const expiredSubscriptions = subscriptionList.filter((product) =>
    isExpiredSubscription(state, product)
  )

  return expiredSubscriptions || []
}

export const getUnpurchaseableSubscriptions = (state) => {
  const expiredSubscriptions = getExpiredSubscriptions(state)
  const insufficientPermissionsSubscriptions =
    getSubscriptionsWithInsufficientPermissions(state)

  const unpurchaseableSubscriptions = [
    ...expiredSubscriptions,
    ...insufficientPermissionsSubscriptions,
  ]

  return [...new Set(unpurchaseableSubscriptions)]
}

export const getCurrentInvalidSubscription = (state, checkPermission) => {
  const unpurchaseableSubscriptions = checkPermission
    ? getUnpurchaseableSubscriptions(state)
    : getExpiredSubscriptions(state)

  if (!unpurchaseableSubscriptions?.length) {
    return null
  } else if (unpurchaseableSubscriptions.includes(activeProduct)) {
    return activeProduct
  }

  return unpurchaseableSubscriptions[0]
}

export const getCurrentInvalidSubscriptionForCart = (state) => {
  const hasActiveProductCard = hasCard(state, activeProduct)
  const insufficientPermissionsSubscriptions =
    getSubscriptionsWithInsufficientPermissions(state)

  const invalidCartSubscriptions = getInvalidSubscriptionsForCart(state).filter(
    (product) => !insufficientPermissionsSubscriptions.includes(product)
  )

  return (
    !(hasActiveProductCard && !invalidCartSubscriptions.length) &&
    (invalidCartSubscriptions.includes(activeProduct)
      ? activeProduct
      : invalidCartSubscriptions?.[0])
  )
}
