import { put, takeEvery, delay } from 'redux-saga/effects'
import { nanoid } from 'nanoid'

import {
  notificationsCreate,
  notificationsAdd,
  notificationsRemove,
} from '@/store/notifications'
import { NOTIFICATIONS } from '@/constants'

export function* createNotification({ payload }) {
  const newPayload = {
    ...payload,
    id: nanoid(),
  }

  if (payload === NOTIFICATIONS.TYPES.PAGE_LOAD) {
    localStorage.setItem('notification', JSON.stringify(newPayload))
  } else {
    yield put(notificationsAdd(newPayload))

    if (newPayload.autoHideDelayTime) {
      yield delay(newPayload.autoHideDelayTime)
      yield put(notificationsRemove({ id: newPayload.id }))
    }
  }
}

export function* watchNotifications() {
  yield takeEvery(notificationsCreate, createNotification)
}
