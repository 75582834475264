import { call, put, takeEvery } from 'redux-saga/effects'

import { GET, OK } from '@/constants/utils'
import { createApiRequest } from '@/utils/api'
import { fetchCampaigns, fetchPublicCampaigns } from '@/store/campaigns'

const getCampaignApps = (item) => {
  if (!item || !item?.applications) {
    return []
  }

  return item?.applications.map((app) => ({
    ...app,
    shortDescription: app.description, // TODO: update API to use shortDescription
  }))
}

function* requestCampaignsList() {
  try {
    const { status, json, error } = yield call(createApiRequest, {
      method: GET,
      path: '/campaigns',
    })

    if (status === OK) {
      yield put(
        fetchCampaigns.fulfilled({
          items: json.map((item) => {
            return {
              ...item,
              applications: getCampaignApps(item),
            }
          }),
        })
      )
    } else {
      throw new Error(`Campaigns couldn't be fetched: ${error.message}`)
    }
  } catch (error) {
    yield put(fetchCampaigns.rejected({ error: error?.message }))
  }
}

function* requestPublicCampaignsList() {
  try {
    const { status, json, error } = yield call(createApiRequest, {
      method: GET,
      path: '/campaigns',
      noauth: true,
    })

    if (status === OK) {
      yield put(
        fetchPublicCampaigns.fulfilled({
          items: json.map((item) => {
            return {
              ...item,
              applications: getCampaignApps(item),
            }
          }),
        })
      )
    } else {
      throw new Error(`Campaigns couldn't be fetched: ${error.message}`)
    }
  } catch (error) {
    yield put(fetchPublicCampaigns.rejected({ error: error?.message }))
  }
}

export function* watchCampaignsRequests() {
  yield takeEvery(fetchPublicCampaigns.pending, requestPublicCampaignsList)
  yield takeEvery(fetchCampaigns.pending, requestCampaignsList)
}
