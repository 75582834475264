export const gtmHeadScript = (
  <script
    key="gtm-head"
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
    f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${
      process.env.GOOGLE_TAG_MANAGER_ID
    }');</script>`,
    }}
  />
)

export const gtmBodyScript = (
  <noscript key="gtm-body">
    <iframe
      title="gtm"
      src={`https://www.googletagmanager.com/ns.html?id=${
        process.env.GOOGLE_TAG_MANAGER_ID
      }`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
    />
  </noscript>
)

export const piwikHeadScript = (
  <script
    key="piwik-head"
    dangerouslySetInnerHTML={{
      __html: `(function(window, document, dataLayerName, id) {
      function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
      var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
      var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
      document.write('<script src="//livechat.containers.piwik.pro/'+id+'.sync.js' + qPString + '"></' + 'script>');
      })(window, document, 'dataLayer', '${process.env.GATSBY_PIWIK_ID}')`,
    }}
  />
)

export const piwikBodyScript = (
  <script
    key="piwik-body"
    dangerouslySetInnerHTML={{
      __html: `(function(window, document, dataLayerName, id) {
        window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
        function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
        var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
        var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
        tags.async=!0,tags.src="//livechat.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
        !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
        })(window, document, 'dataLayer', '${process.env.GATSBY_PIWIK_ID}');`,
    }}
  />
)

export const piwikBodyNoscript = (
  <noscript key="piwik-body-noscript">
    <iframe
      title="piwik-body-noscript"
      src={`//livechat.containers.piwik.pro/${
        process.env.GATSBY_PIWIK_ID
      }/noscript.html`}
      style={{ display: 'none', visibility: 'hidden' }}
      height="0"
      width="0"
    />
  </noscript>
)
