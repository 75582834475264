import compose from 'lodash/fp/compose'
import fromPairs from 'lodash/fp/fromPairs'
import map from 'lodash/fp/map'
import split from 'lodash/fp/split'
import partialRight from 'lodash/fp/partialRight'
import trimStart from 'lodash/fp/trimStart'
import join from 'lodash/fp/join'
import toPairs from 'lodash/fp/toPairs'

import { canUseWindow } from './window'

const encodedCommaChar = '%2C'

export const decodeQueryString = compose(
  fromPairs,
  map(
    compose(
      map(decodeURIComponent),
      split('=')
    )
  ),
  split('&'),
  partialRight(trimStart.convert({ fixed: false }), ['?']),
  partialRight(trimStart.convert({ fixed: false }), ['#'])
)

export const buildQueryString = compose(
  join('&'),
  map(
    compose(
      join('='),
      map(encodeURIComponent)
    )
  ),
  toPairs
)

export const convertParamsToObject = (params) =>
  [...params.entries()].reduce((acc, [key, value]) => {
    const parsedValue = value.split(',').length > 1 ? value.split(',') : value
    return { ...acc, [key]: parsedValue }
  }, {})

export const convertObjectToParams = (object) => {
  if (!object || !Object.entries(object).length) {
    return ''
  }

  const objectValues = Object.entries(object) || []
  let params = ''

  objectValues.forEach(([key, value]) => {
    const specialChar = !params.length ? '?' : '&'

    if (Array.isArray(value)) {
      const convertedArray = value.join(encodedCommaChar)
      params += `${specialChar}${key}=${convertedArray}`
    } else {
      params += `${specialChar}${key}=${value}`
    }
  })

  return params
}

export const filterOutParams = (values, supportedParams) => {
  if (!values) {
    return {}
  }

  Object.entries(values).forEach(([key, value]) => {
    if (
      !value ||
      (value && value.length !== undefined && !value.length) ||
      (supportedParams && !supportedParams.includes(key))
    ) {
      delete values[key]
    }
  })

  return values
}

export const getUrlParams = () => {
  const queryString = canUseWindow && window.location.search
  const urlParams = new URLSearchParams(queryString)

  return convertParamsToObject(urlParams)
}

export const getParam = (param) => {
  const queryParams = getUrlParams()
  return queryParams[param] || null
}

export const setUrlParams = (values, supportedParams) => {
  const checkValues = filterOutParams(values, supportedParams)
  let params = convertObjectToParams(checkValues)

  if (canUseWindow) {
    if (!params) {
      params = window.location.pathname
    }

    if (window.history.replaceState) {
      window.history.replaceState(null, null, params)
    }
  }
}

export const updateUrl = (url) => {
  if (window.location.hash !== url) {
    // Safari has a limitation (100 times per 30 secs)
    // This is very unlikely to happen but possible
    try {
      window.history.replaceState(null, null, url)
    } catch {}
  }
}
