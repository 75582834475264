import * as Sentry from '@sentry/react'
import { ignoreErrors } from './cases/ignore-errors'

export const initSentry = (activeEnv) => {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    release: process.env.GATSBY_GIT_SHA,
    project: process.env.GATSBY_SENTRY_PROJECT,
    environment: activeEnv,
    ignoreErrors,
  })
}
