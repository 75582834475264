import { call, put, select, takeEvery } from 'redux-saga/effects'

import { OK } from '@/constants/utils'
import { fetchInReviewApps } from '@/store/inReviewApps'
import { userLogIn, userLogOut } from '@/store/user'
import { canInstallApplications } from '@/store/selectors'
import appsApi from '@/apis/apps'

function* requestAppList() {
  try {
    const { status, json } = yield call(appsApi.readAllInReview)

    if (status === OK) {
      yield put(
        fetchInReviewApps.fulfilled({
          items:
            json?.reduce((prev, next, i) => {
              prev[next.id] = next
              return prev
            }, {}) || {},
        })
      )
    } else {
      throw new Error("In review app list couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchInReviewApps.rejected({ error: error?.message }))
  }
}

export function* requestsOnLoginSuccess() {
  const canInstall = yield select(canInstallApplications)

  if (canInstall) {
    yield put(fetchInReviewApps.pending())
  }
}

export function* requestsOnLogoutSuccess() {
  // Reset inReview apps
  yield put(
    fetchInReviewApps.fulfilled({
      items: {},
    })
  )
}

export function* watchInReviewAppsRequests() {
  yield takeEvery(fetchInReviewApps.pending, requestAppList)
  yield takeEvery(userLogIn.fulfilled, requestsOnLoginSuccess)
  yield takeEvery(userLogOut.fulfilled, requestsOnLogoutSuccess)
}
