import merge from 'lodash.merge'
import baseTheme from 'gatsby-theme-marketplace-base/src/theme/index'

const theme = merge({}, baseTheme, {
  fontFamily: '"Source Sans Pro", sans-serif',
  layout: {
    breadcrumbs: {
      borderColor: baseTheme.colors.gray100,
    },
  },
  sidebar: {
    maxWidth: '300px',
  },
  font: {
    bold: 600,
  },
  boxShadows: {
    sidebar: '3px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  header: {
    height: '52px',
  },
})

export default theme
