import { put, call, takeEvery } from 'redux-saga/effects'

import { createApiRequest } from '@/utils/api'
import { GET, OK } from '@/constants/utils'
import { fetchDeveloper } from '@/store/developers'

function* requestDeveloperProfile({ payload: { id } }) {
  try {
    const { status, json } = yield call(createApiRequest, {
      method: GET,
      path: `/developers/${id}/profile`,
      noauth: true,
    })

    if (status === OK) {
      yield put(
        fetchDeveloper.fulfilled({
          item: { id, ...json },
          receivedAt: Date.now(),
        })
      )
    } else {
      throw new Error("Developer profile couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchDeveloper.rejected({ error: error?.message }))
  }
}

export function* watchDeveloperProfilesRequests() {
  yield takeEvery(fetchDeveloper.pending, requestDeveloperProfile)
}
