import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  rates: {
    items: {},
    isFetching: false,
    isFetched: false,
    isSendingRate: false,
    error: null,
  },
  summary: {
    product: null,
    rate: null,
    votes: null,
    histogram: null,
    reviews: null,
    isFetching: false,
    isFetched: false,
    error: null,
  },
}

export const fetchFeedbackRates = createAsyncActions('feedback/fetchRates')
export const fetchFeedbackSummary = createAsyncActions('feedback/fetchSummary')
export const sendFeedback = createAsyncActions('feedback/send')

/*
  List of applications created by currently logged in user
*/
const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  extraReducers: {
    [fetchFeedbackRates.pending]: (state) => {
      state.rates = {
        ...state.rates,
        isFetching: true,
        error: null,
      }
    },
    [fetchFeedbackRates.fulfilled]: (state, { payload }) => {
      state.rates = {
        ...state.rates,
        isFetching: false,
        isFetched: true,
        items: payload.items.reduce((prev, item) => {
          prev[(item?.id)] = item
          return prev
        }, {}),
      }
    },
    [fetchFeedbackRates.rejected]: (state, { payload }) => {
      state.rates = {
        ...state.rates,
        error: payload.error,
        isFetching: false,
      }
    },
    [fetchFeedbackSummary.pending]: (state) => {
      state.summary = {
        ...state.summary,
        product: null,
        rate: null,
        votes: null,
        histogram: null,
        reviews: null,
        isFetching: true,
        error: null,
      }
    },
    [fetchFeedbackSummary.fulfilled]: (state, { payload }) => {
      state.summary = {
        ...state.summary,
        ...payload.summary,
        isFetching: false,
        isFetched: true,
      }
    },
    [fetchFeedbackSummary.rejected]: (state, { payload }) => {
      state.summary = {
        ...state.summary,
        isFetching: false,
        error: payload.error,
      }
    },
    [sendFeedback.pending]: (state) => {
      state.rates = {
        ...state.rates,
        isSendingRate: true,
        error: null,
      }
    },
    [sendFeedback.fulfilled]: (state, { payload }) => {
      const { summary } = payload
      const id = summary?.product

      state.rates = {
        ...state.rates,
        isSendingRate: false,
        items: {
          ...state.rates.items,
          [id]: {
            ...state.rates.items[id],
            id,
            rate: summary.rate,
            votes: summary.votes,
          },
        },
      }
      state.summary = {
        ...state.summary,
        ...summary,
      }
    },
    [sendFeedback.rejected]: (state, { payload }) => {
      state.rates = {
        ...state.rates,
        error: payload.error,
        isSendingRate: false,
      }
    },
  },
})

export const reducer = feedbackSlice.reducer
