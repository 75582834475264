import Icon from '@/components/Icon'
import { PRODUCT } from '@/utils'

export const getDesignationLabel = (product) => {
  if (!product) {
    product = PRODUCT.LiveChat
  }

  const productLabel = Object.keys(PRODUCT).find(
    (key) => PRODUCT[key] === product
  )

  return productLabel
}

export const getDesignationIcon = (product) => {
  const productLabel = getDesignationLabel(product)

  return <Icon label={productLabel} />
}

export const getMultipleDesignations = (items = []) => {
  if (items?.length === 1) {
    return getDesignationLabel(items[0])
  }

  const lastItem = items.pop()
  const designations = items.map((item) => getDesignationLabel(item)).join(', ')

  return `${designations} and ${getDesignationLabel(lastItem)}`
}

export const normalizeItemProductType = (item) => {
  if (!item) {
    return {}
  }

  return {
    ...item,
    product: item?.product === '' ? PRODUCT.LiveChat : item.product,
  }
}
