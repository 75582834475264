import { activeProduct, PRODUCT } from '../environment'

export const getAccountsApiUrl = (product = activeProduct) => {
  switch (product) {
    case PRODUCT.HelpDesk:
      return process.env.GATSBY_HD_ACCOUNTS_URL

    case PRODUCT.LiveChat:
    default:
      return process.env.GATSBY_LC_ACCOUNTS_URL
  }
}

export const getProductApiUrl = (product = activeProduct) => {
  switch (product) {
    case PRODUCT.HelpDesk:
      return `${process.env.GATSBY_HD_API_URL}/v1/`

    case PRODUCT.LiveChat:
    default:
      return `${process.env.GATSBY_LC_API_URL}/v2/`
  }
}
