import React from 'react'
import { string } from 'prop-types'

import AllAppsIcon from '@/assets/icons/all-apps.inline.svg'
import BasketIcon from '@/assets/icons/basket.inline.svg'
import ChannelsIcon from '@/assets/icons/channels.inline.svg'
import IntegrationsIcon from '@/assets/icons/integrations.inline.svg'
import SurveysIcon from '@/assets/icons/surveys.inline.svg'
import MailIcon from '@/assets/icons/mail.inline.svg'
import ReportsIcon from '@/assets/icons/reports.inline.svg'
import RocketIcon from '@/assets/icons/rocket.inline.svg'
import ChatBotIcon from '@/assets/icons/logo-chatbot.inline.svg'
import KBIcon from '@/assets/icons/logo-knowledgebase.inline.svg'
import HelpDeskIcon from '@/assets/icons/logo-helpdesk.inline.svg'
import StarterIcon from '@/assets/icons/starter.inline.svg'
import StarIcon from '@/assets/icons/star.inline.svg'
import ContactsIcon from '@/assets/icons/contacts.inline.svg'
import RemoteDesktopIcon from '@/assets/icons/remote-desktop.inline.svg'
import LeadGenerationIcon from '@/assets/icons/lead-gen.inline.svg'
import ChatbotsIcon from '@/assets/icons/chatbots.inline.svg'
import FilesSharingIcon from '@/assets/icons/files-sharing.inline.svg'
import SocialmediaIcon from '@/assets/icons/socialmedia.inline.svg'
import LiveChatIcon from '@/assets/icons/logo-livechat-small.inline.svg'
import ExpertsIcon from '@/assets/icons/experts.inline.svg'
import FireIcon from '@/assets/icons/fire.inline.svg'
import UaFlagIcon from '@/assets/icons/ua-flag.inline.svg'
import AppHubIcon from '@/assets/icons/app-hub.inline.svg'
import SaleIcon from '@/assets/icons/sale.inline.svg'
import DollarIcon from '@/assets/icons/payment-single.inline.svg'
import { URLS } from '@/constants'

const CategoryIcon = ({ label, ...rest }) => {
  switch (label) {
    case 'All':
    case URLS.APPS.LABEL:
      return <AllAppsIcon {...rest} />

    case URLS.CATEGORY.ECOMMERCE.LABEL:
      return <BasketIcon {...rest} />

    case URLS.CATEGORY.CRM.LABEL:
      return <ChannelsIcon {...rest} />

    case URLS.CATEGORY.CMS.LABEL:
      return <IntegrationsIcon {...rest} />

    case URLS.CATEGORY.HELPDESK.LABEL:
      return <SurveysIcon {...rest} />

    case URLS.CATEGORY.EMAIL_MARKETING.LABEL:
      return <MailIcon {...rest} />

    case URLS.CATEGORY.ANALYTICS.LABEL:
      return <ReportsIcon {...rest} />

    case URLS.NEW_AND_NOTEWORTHY.LABEL:
    case URLS.HACKATHON_WINNERS.LABEL:
      return <RocketIcon {...rest} />

    case URLS.HOLIDAY_SEASON.LABEL:
      return <FireIcon {...rest} />

    case URLS.SUPPORT_UA.LABEL:
      return <UaFlagIcon {...rest} />

    case URLS.STAFF_TOP_PICKS.LABEL:
    case URLS.RECOMMENDED_PARTNERS.LABEL:
      return <StarIcon {...rest} />

    case URLS.STARTER_PACK.LABEL:
      return <StarterIcon {...rest} />

    case URLS.CHATBOT.LABEL:
      return <ChatBotIcon {...rest} />

    case URLS.KB.LABEL:
      return <KBIcon {...rest} />

    case URLS.HELPDESK.LABEL:
      return <HelpDeskIcon {...rest} />

    case URLS.CATEGORY.CONTACT_MANAGEMENT.LABEL:
      return <ContactsIcon {...rest} />

    case URLS.CATEGORY.DESKTOP_SHARING.LABEL:
      return <RemoteDesktopIcon {...rest} />

    case URLS.CATEGORY.LEAD_GENERATION.LABEL:
      return <LeadGenerationIcon {...rest} />

    case URLS.CATEGORY.CHATBOTS.LABEL:
      return <ChatbotsIcon {...rest} />

    case URLS.CATEGORY.FILES_SHARING.LABEL:
      return <FilesSharingIcon {...rest} />

    case URLS.CATEGORY.SOCIAL_MEDIA.LABEL:
      return <SocialmediaIcon {...rest} />

    case URLS.LIVECHAT.LABEL:
      return <LiveChatIcon {...rest} />

    case URLS.EXPERTS.LABEL:
      return <ExpertsIcon {...rest} />

    case URLS.OMNICHANNEL.LABEL:
      return <AppHubIcon {...rest} />

    case URLS.ENHANCE_ECOMMERCE.LABEL:
      return <DollarIcon {...rest} />

    case URLS.BLACK_FRIDAY.LABEL:
      return <SaleIcon />

    default:
      return null
  }
}

CategoryIcon.propTypes = {
  label: string,
}

export default CategoryIcon
