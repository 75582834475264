/* eslint-disable no-console */
/* eslint-disable no-restricted-globals */
import startsWith from 'lodash/startsWith'
import { navigate } from 'gatsby'

import { URLS } from '@/constants'
import { JSONParse } from '@/utils/func'
import { isInNestedIframe } from './iframe'

// POST MESSAGE EVENTS SUPPORTED IN THE MARKETPLACE AND AGENT APP
export const PM_EVENT = {
  BOTENGINE_INSTALLED: 'botengine_installed',
  BOTENGINE_REMOVED: 'botengine_removed',
  LOADED: 'loaded',
  NAVIGATE: 'navigate',
  ROUTE_CHANGE: 'route_change',
  APP_INSTALLED: 'app_installed',
  APP_REMOVED: 'app_removed',
  APP_EVENT: 'app_event',
  APP_PURCHASED: 'app_purchased',
  CART_PURCHASED: 'cart_purchased',
  MARKETPLACE_CART_UPDATE: 'marketplace_cart_update',
}

export const initPostMessageListeners = () => {
  const receiveMessage = (event) => {
    if (
      startsWith(event.origin, process.env.GATSBY_IFRAME_URL) ||
      startsWith(event.origin, 'https://localhost') ||
      startsWith(event.origin, 'http://localhost') ||
      startsWith(event.origin, 'https://my.') ||
      startsWith(event.origin, 'http://my.')
    ) {
      handleAgentAppEvents(event)
    }
  }

  window.addEventListener('message', receiveMessage, false)
}

const handleAgentAppEvents = (event) => {
  const data = JSONParse(event.data)

  switch (data?.event_name) {
    case PM_EVENT.NAVIGATE:
      let pathname = data.event_data.pathname.replace('/apps/marketplace', '')

      // /apps route is the default route for AA
      if (!pathname || pathname === '/') {
        pathname = URLS.APPS.LINK
      }

      navigate(pathname)
      break

    default:
      return false
  }
}

export const sendPostMessage = (eventName, eventData) => {
  switch (eventName) {
    case PM_EVENT.LOADED:
    case PM_EVENT.ROUTE_CHANGE:
    case PM_EVENT.APP_INSTALLED:
    case PM_EVENT.APP_REMOVED:
    case PM_EVENT.APP_EVENT:
    case PM_EVENT.APP_PURCHASED:
    case PM_EVENT.CART_PURCHASED:
    case PM_EVENT.NAVIGATE:
    case PM_EVENT.MARKETPLACE_CART_UPDATE:
      const message = JSON.stringify({
        event_name: eventName,
        event_data: eventData || null,
      })

      if (isInNestedIframe()) {
        return top.postMessage(message, '*')
      }
      return parent.postMessage(message, '*')

    default:
      return false
  }
}
