import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  isInitialized: false,
  error: null,
  items: {},
}

export const fetchAgents = createAsyncActions('agents/fetch')

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  extraReducers: {
    [fetchAgents.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchAgents.fulfilled]: (state, { payload }) => {
      state.isInitialized = true
      state.isFetching = false
      state.items = payload.items
    },
    [fetchAgents.rejected]: (state, { payload }) => {
      state.isInitialized = true
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const reducer = agentsSlice.reducer
