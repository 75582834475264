import AccountsSDK from '@livechat/accounts-sdk'

import {
  setCookie,
  getCookie,
  removeCookie,
  canUseWindow,
  decodeQueryString,
} from '@/utils/browser'
import { OK, AUTH_TYPE } from '@/constants/utils'
import { getAccountsApiUrl } from '@/utils/api/urls'
import { PRODUCT } from '@/utils'

const SIGN_IN_TIMEOUT = 2500 // ms

const TOKEN_KEY = 'access_token'
const TOKEN_CYPRESS = 'cy-access-token'
const TOKEN_PARENT = 'credentials.access_token'

const ERROR = {
  TRANSACTION: 'Unauthorized transaction',
}

class AccountsSDKManager {
  constructor() {
    this._sdk = new AccountsSDK({
      client_id: process.env.GATSBY_ACCOUNTS_CLIENTID,
      server_url: process.env.GATSBY_LC_ACCOUNTS_URL,
    })

    this._token = this._getCachedToken()
  }

  _getCachedToken = () => {
    if (canUseWindow && window.Cypress) {
      return getCookie(TOKEN_KEY) || localStorage.getItem(TOKEN_CYPRESS)
    }

    if (canUseWindow && window.location.hash) {
      const { access_token } = decodeQueryString(window.location.hash)

      if (access_token) {
        window.location.hash = ''
        return decodeURIComponent(access_token)
      }
    }

    if (canUseWindow && getCookie(TOKEN_PARENT)) {
      return getCookie(TOKEN_PARENT)
    }

    if (canUseWindow && window.sessionStorage) {
      const sessionToken = window.sessionStorage.getItem(TOKEN_KEY)

      if (sessionToken) {
        return sessionToken
      }
    }

    return getCookie(TOKEN_KEY)
  }

  _fetchUserInfo = async (access_token) => {
    try {
      const headers = { Authorization: `Bearer ${access_token}` }
      const response = await fetch(
        getAccountsApiUrl(PRODUCT.LiveChat) + `/v2/info`,
        { headers }
      )

      if (response?.status === OK) {
        return await response.json()
      } else {
        throw new Error('Cannot fetch info or token is invalid')
      }
    } catch (error) {
      this.clear()
      return this.authorize(AUTH_TYPE.iframe)
    }
  }

  _preserveToken({ access_token }) {
    if (access_token) {
      this._token = access_token

      window.sessionStorage.setItem(TOKEN_KEY, access_token)

      setCookie(TOKEN_KEY, access_token, {
        days: 1,
        path: '/',
      })
    }
  }

  _verifyTransaction = (data) => {
    const transaction = this._sdk.verify(data)

    if (transaction != null) {
      return data
    } else {
      throw new Error(ERROR.TRANSACTION)
    }
  }

  _iframe = async () => {
    try {
      // It helps start fetching applications list after SIGN_IN_TIMEOUT because standard iframe timeout takes to long (5sec)
      const iframeTimeout = new Promise((_, reject) =>
        setTimeout(() => reject('Timeout'), SIGN_IN_TIMEOUT)
      )

      const iframeFlow = new Promise((resolve, reject) => {
        this._sdk
          .iframe()
          .authorize()
          .then(resolve)
          .catch(reject)
      })

      return await Promise.race([iframeFlow, iframeTimeout])
    } catch (error) {
      throw error
    }
  }

  authorize = async (type) => {
    try {
      let data
      switch (type) {
        case AUTH_TYPE.popup:
          data = await this._sdk.popup().authorize()
          break

        case AUTH_TYPE.redirect:
          return await this._sdk.redirect().authorize()

        case AUTH_TYPE.iframe:
        default:
          if (this._token) {
            data = await this._fetchUserInfo(this._token)
            this._preserveToken(data)

            return data
          }

          data = await this._iframe()
          break
      }

      const verifiedData = this._verifyTransaction(data)
      this._preserveToken(verifiedData)

      return data
    } catch (error) {
      throw error
    }
  }

  clear = () => {
    removeCookie(TOKEN_KEY)
    window.sessionStorage.removeItem(TOKEN_KEY)
    this._token = null
  }
}

export default AccountsSDKManager
