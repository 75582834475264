export const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isInNestedIframe = () => {
  try {
    return window.parent !== window.top
  } catch (e) {
    return true
  }
}
