import { all } from 'redux-saga/effects'

import { watchExpertsRequests } from '@/store/experts/sagas'
import { watchAgentsRequests } from '@/store/agents/sagas'
import { watchCampaignsRequests } from '@/store/campaigns/sagas'
import { watchSubscriptionRequests } from '@/store/subscription/sagas'
import { watchStatsRequests } from '@/store/stats/sagas'
import { watchDeveloperProfilesRequests } from '@/store/developers/sagas'
import { watchFeedbackRequests } from '@/store/feedback/sagas'
import { watchPrivateAppsRequests } from '@/store/privateApps/sagas'
import { watchInReviewAppsRequests } from '@/store/inReviewApps/sagas'
import { watchPublishedAppsRequests } from '@/store/publishedApps/sagas'
import { watchInstalledAppsRequests } from '@/store/installedApps/sagas'
import { watchNotifications } from '@/store/notifications/sagas'
import { watchUserRequests } from '@/store/user/sagas'
import { watchPaymentRequests } from '@/store/payment/sagas'
import { watchCartRequests } from '@/store/cart/sagas'
import { watchResourcesRequests } from '@/store/resources/sagas'
import { watchDirectChargesRequests } from '@/store/directCharges/sagas'
import { watchRecurrentChargesRequests } from '@/store/recurrentCharges/sagas'

export default function* rootSaga() {
  yield all([
    watchStatsRequests(),
    watchUserRequests(),
    watchAgentsRequests(),
    watchDeveloperProfilesRequests(),
    watchPrivateAppsRequests(),
    watchInReviewAppsRequests(),
    watchPublishedAppsRequests(),
    watchInstalledAppsRequests(),
    watchExpertsRequests(),
    watchCampaignsRequests(),
    watchSubscriptionRequests(),
    watchNotifications(),
    watchDirectChargesRequests(),
    watchRecurrentChargesRequests(),
    watchPaymentRequests(),
    watchCartRequests(),
    watchResourcesRequests(),
    watchFeedbackRequests(),
  ])
}
