import { createAccountsApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'

export const deleteAll = () =>
  createAccountsApiRequest({
    method: HttpMethod.DELETE,
    path: `/v2/sessions`,
  })

export default {
  deleteAll,
}
