import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  hasInitialized: false,
  error: null,
  items: {},
}

export const fetchInReviewApps = createAsyncActions('apps/inReview')

const inReviewAppsSlice = createSlice({
  name: 'inReviewApps',
  initialState,
  extraReducers: {
    [fetchInReviewApps.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchInReviewApps.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
      state.hasInitialized = true
    },
    [fetchInReviewApps.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
      state.hasInitialized = true
    },
  },
})

export const reducer = inReviewAppsSlice.reducer
