import { canUseWindow, isInIframe, isInNestedIframe } from '@/utils/browser'

const baseUrl = process.env.GATSBY_BASE_URL

export const getMarketplaceSourceUrl = () => {
  if (!canUseWindow) {
    return baseUrl
  }

  if (isInNestedIframe()) {
    return baseUrl
  } else if (isInIframe()) {
    return `${process.env.GATSBY_IFRAME_URL}/marketplace`
  }

  return baseUrl
}
