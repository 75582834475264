// @ts-check
// @ts-ignore
import storeMetrics from '@livechat/store-metrics'
import 'react-app-polyfill/ie11'
import 'js-polyfills/url'

import { wrapWithProviders } from './src/providers'
import { initSentry } from './src/services/sentry'
import { initRoutine } from '@/utils/initRoutine'
import { activeEnv, isAgentApp, isProd, isTestEnv } from '@/utils/environment'
import { pushEvent } from '@/utils/analytics'
import {
  sendPostMessage,
  PM_EVENT,
  scrollTo,
  initializeAdblockDetector,
} from '@/utils/browser'

if (!isTestEnv) {
  initRoutine()
}

if (isProd) {
  initSentry(activeEnv)
}

/**
 * @type {import ('gatsby').GatsbyBrowser['onRouteUpdate']}
 */
export const onRouteUpdate = ({ location: { pathname, hash, search } }) => {
  if (hash) {
    window.setTimeout(() => scrollTo(hash))
  }

  let path = `/marketplace${pathname}${search}`

  // We cannot move this action into website's gatsby-browser.js, because it would create a separate onRouteUpdate handler instead of overriding this one
  if (isAgentApp) {
    let normalizedPath = pathname

    if (pathname === '/apps/marketplace' || pathname === '/apps/marketplace/') {
      normalizedPath = '/apps'
    }

    if (normalizedPath.length > 0 && !normalizedPath.startsWith('/')) {
      normalizedPath = `/${normalizedPath}`
    }

    if (normalizedPath.endsWith('/')) {
      normalizedPath = normalizedPath.slice(0, -1)
    }

    path = `/apps/marketplace${normalizedPath}${search}`
  }

  sendPostMessage(PM_EVENT.ROUTE_CHANGE, {
    pathname: path,
  })

  pushEvent({ event: 'virtualPageView', payload: {} })
}

export const onInitialClientRender = () => {
  storeMetrics()

  initializeAdblockDetector()
}

export const wrapRootElement = wrapWithProviders
