import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseQuery } from '@/utils/api'
import type { ICheckConsentResponse } from './interfaces'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: customFetchBaseQuery({
    baseUrl: `${process.env.GATSBY_LC_ACCOUNTS_URL}/v2`,
  }),
  endpoints: ({ query }) => ({
    checkConsent: query<ICheckConsentResponse, string>({
      query: (clientId, coverage = 'account') => ({
        path: `/clients/${clientId}/consent/status?coverage=${coverage}`,
      }),
    }),
  }),
})

export const { useCheckConsentQuery, reducer, reducerPath, middleware } =
  accountsApi
