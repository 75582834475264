import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  utm: {},
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    logAmplitudeEvent(state, { payload }) {
      return payload
    },
    utmParameters(state, { payload }) {
      state.utm = {
        ...state.utm,
        ...payload,
      }
    },
  },
})

export const { utmParameters, logAmplitudeEvent } = statsSlice.actions
export const reducer = statsSlice.reducer
