import { call, put, takeEvery, throttle } from 'redux-saga/effects'

import { fetchFeedbackRates } from '@/store/feedback'
import { serviceList, serviceDetails } from '@/store/services'
import { fetchPublishedApps, fetchAppInfo } from '@/store/publishedApps'
import { normalizeItemProductType } from '@/utils/products'
import { createApiRequest } from '@/utils/api'
import { GET, OK } from '@/constants/utils'
import appsApi from '@/apis/apps'

function* requestAppDetails({ payload: { slug } }) {
  try {
    const { status, json } = yield call(createApiRequest, {
      mathod: GET,
      path: `/applications/${slug}/published`,
      tokenRequired: false,
    })

    if (status === OK) {
      yield put(
        fetchAppInfo.fulfilled({
          item: normalizeItemProductType(json),
        })
      )

      if (json?.customProps?.isService === 'true') {
        yield put(
          serviceDetails({
            item: json,
          })
        )
      }
    } else {
      throw new Error("App details couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchAppInfo.rejected({ error: error?.message }))
  }
}

function* requestPublishedAppList() {
  try {
    yield put(fetchFeedbackRates.pending())

    const { status, json } = yield call(appsApi.getPublishedApps)

    if (status === OK) {
      yield put(
        fetchPublishedApps.fulfilled({
          items: json.reduce((prev, item) => {
            prev[item.id] = normalizeItemProductType(item)
            return prev
          }, {}),
        })
      )

      // Services are using the same endpoint. In order to optimize api calls we are storing them from here
      yield put(
        serviceList({
          items: json.reduce((prev, item) => {
            if (item.customProps?.isService === 'true') {
              prev[item.id] = item
            }

            return prev
          }, {}),
        })
      )
    } else {
      throw new Error("Apps list couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchPublishedApps.rejected({ error: error?.message }))
  }
}

export function* watchPublishedAppsRequests() {
  yield takeEvery(fetchAppInfo.pending, requestAppDetails)

  // Fetch every 15 minutes to keep data fresh
  yield throttle(15000, fetchPublishedApps.pending, requestPublishedAppList)
}
