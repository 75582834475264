import { graphql, useStaticQuery } from 'gatsby'

/*
How to add new image:
1. Place an image in the src/assets/images folder
2. Add extend gatsby query with gatsbyImageData:
  - docs for all parameters used in gatsbyImageData - https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image
  - property name should match filename but with camelCase notation (and without digits at the beggining)
  - use the best quality image, because it leaves the most space for optimization
  - set image quality between 60-80, these values works the best
How gatsby-plugin-image works:
- reduces images sizes
- cuts them to passed dimmensions
- creates multiple image versions for srcSet (1x, 1,5x, 2x)
- creates scrSet with all the fastest supported file formats
- gatsby-plugin-image's components will fallback to png/jpg if browser is not supporting webp format
*/
export const useImages = () => {
  // const categoriesImage = graphql`
  //   fragment categoriesImage on File {
  //     childImageSharp {
  //       gatsbyImageData(width: 400, quality: 60, layout: FIXED, placeholder: NONE)
  //     }
  //   }
  // `

  // /**
  // * Please note this image fragment requires a wrapper
  // */
  // const headerBackgroundImage = graphql`
  //   fragment headerBackgroundImage on File {
  //     childImageSharp {
  //       gatsbyImageData(width: 1400, quality: 60, layout: CONSTRAINED)
  //     }
  //   }
  // `

  return useStaticQuery(graphql`
    fragment headerBackgroundImage on File {
      childImageSharp {
        gatsbyImageData(width: 1400, quality: 60, layout: CONSTRAINED)
      }
    }

    fragment categoriesImage on File {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 60
          layout: FIXED
          placeholder: NONE
        )
      }
    }

    query {
      thankYou: file(relativePath: { eq: "images/checkout/thank-you@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            quality: 60
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      reviewOrder: file(
        relativePath: { eq: "images/checkout/review-order.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            quality: 60
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      genericApps: file(
        relativePath: { eq: "images/app-hero/generic-apps-2x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 320
            quality: 60
            layout: FIXED
            placeholder: DOMINANT_COLOR
          )
        }
      }
      placeholder: file(
        relativePath: { eq: "images/app-logos/placeholder.png" }
      ) {
        ...categoriesImage
      }
      analytics: file(
        relativePath: { eq: "images/categories-covers/analytics@4x.png" }
      ) {
        ...categoriesImage
      }
      cms: file(relativePath: { eq: "images/categories-covers/cms@4x.png" }) {
        ...categoriesImage
      }
      crm: file(relativePath: { eq: "images/categories-covers/crm@4x.png" }) {
        ...categoriesImage
      }
      default: file(
        relativePath: { eq: "images/categories-covers/default@4x.png" }
      ) {
        ...categoriesImage
      }
      ecommerce: file(
        relativePath: { eq: "images/categories-covers/ecommerce@4x.png" }
      ) {
        ...categoriesImage
      }
      helpdesk: file(
        relativePath: { eq: "images/categories-covers/helpdesk@4x.png" }
      ) {
        ...categoriesImage
      }
      helpdeskApp: file(
        relativePath: { eq: "images/categories-covers/helpdesk-app@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 60, layout: FIXED)
        }
      }
      heroHangouts: file(
        relativePath: { eq: "images/categories-covers/hero-hangouts@3x.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 400, quality: 60, layout: FIXED)
        }
      }
      email: file(
        relativePath: { eq: "images/categories-covers/email@4x.png" }
      ) {
        ...categoriesImage
      }
      chatsupport: file(
        relativePath: { eq: "images/partners-covers/365chatsupport@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      bespokechat: file(
        relativePath: { eq: "images/partners-covers/bespokechat@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      blueskychat: file(
        relativePath: { eq: "images/partners-covers/blueskychat@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      chatmate: file(
        relativePath: { eq: "images/partners-covers/chatmate@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      crowdchat: file(
        relativePath: { eq: "images/partners-covers/crowdchat@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      deskmoz: file(
        relativePath: { eq: "images/partners-covers/deskmoz@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      expertsdefault: file(
        relativePath: { eq: "images/partners-covers/expertsdefault@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      helpflow: file(
        relativePath: { eq: "images/partners-covers/helpflow@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      livechatpro: file(
        relativePath: { eq: "images/partners-covers/livechatpro@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      thechatshop: file(
        relativePath: { eq: "images/partners-covers/thechatshop@3x.jpg" }
      ) {
        ...headerBackgroundImage
      }
      campaignLoading: file(
        relativePath: { eq: "images/campaign-loading.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1400
            quality: 60
            layout: CONSTRAINED
            placeholder: NONE
          )
        }
      }
      cartEmptyState: file(
        relativePath: { eq: "images/cart/empty-state.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            quality: 60
            layout: CONSTRAINED
            placeholder: NONE
          )
        }
      }
      enhanceEcommerceBanner: file(
        relativePath: { eq: "images/enhance-ecommerce/bannerHero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE)
        }
      }
      platformCtaHero: file(
        relativePath: { eq: "images/platform-cta-hero.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE)
        }
      }
    }
  `)
}
