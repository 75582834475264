import { createApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'

const create = () =>
  createApiRequest({ method: HttpMethod.POST, path: '/products-carts' })

const close = (id: string) =>
  createApiRequest({ method: HttpMethod.DELETE, path: `/products-carts/${id}` })

const get = (id: string) =>
  createApiRequest({ method: HttpMethod.GET, path: `/products-carts/${id}` })

const getRecent = () =>
  createApiRequest({ method: HttpMethod.GET, path: `/products-carts/recent` })

const addProduct = (id: string, productId: string) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${id}/applications/${productId}`,
  })

const addProducts = (id: string, productIds: string[]) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${id}/applications?id=${productIds.join(',')}`,
  })

const removeProduct = (id: string, productId: string) =>
  createApiRequest({
    method: HttpMethod.DELETE,
    path: `/products-carts/${id}/applications/${productId}`,
  })

const updateProductPlan = (id: string, productId: string, planId: string) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${id}/applications/${productId}/plans/${planId}`,
  })

const startCheckout = (id: string) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${id}/checkout`,
  })

const rejectCheckout = (id: string) =>
  createApiRequest({
    method: HttpMethod.DELETE,
    path: `/products-carts/${id}/checkout`,
  })

const purchase = (id: string) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${id}/purchase`,
  })

const addCoupon = (cartId: string, coupon: string) =>
  createApiRequest({
    method: HttpMethod.PATCH,
    path: `/products-carts/${cartId}/coupon/${coupon}`,
  })

export default {
  create,
  close,
  get,
  addProduct,
  addProducts,
  removeProduct,
  updateProductPlan,
  getRecent,
  startCheckout,
  rejectCheckout,
  purchase,
  addCoupon,
}
