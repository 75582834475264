export const pushEvent = ({ event, payload }) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event,
      ...payload,
    })
  }
}

export const pushGenericEvent = ({
  category = 'Marketplace',
  action,
  label,
}) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'generic',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    })
  }
}
