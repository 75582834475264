import { canUseWindow } from './window'

export const getLocalStorageValue = (key) => {
  try {
    if (typeof localStorage !== undefined && canUseWindow) {
      const rawValue = localStorage.getItem(key)

      if (rawValue) {
        return JSON.parse(rawValue)
      }

      return null
    }
  } catch (error) {
    return null
  }
}

export const getSessionStorageValue = (key) => {
  try {
    if (typeof sessionStorage !== undefined && canUseWindow) {
      const rawValue = sessionStorage.getItem(key)

      if (rawValue) {
        return JSON.parse(rawValue)
      }

      return null
    }
  } catch (error) {
    return null
  }
}

export const removeSessionStorageItem = (key) => {
  try {
    if (typeof sessionStorage !== undefined && canUseWindow) {
      sessionStorage.removeItem(key)
    }
  } catch {}
}

export const setSessionStorageItem = (key, value) => {
  try {
    if (typeof sessionStorage !== undefined && canUseWindow) {
      sessionStorage.setItem(key, value)
    }
  } catch {}
}
