import { select, take, call } from 'redux-saga/effects'
import { Selector } from 'reselect'
import { SagaIterator } from 'redux-saga'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'

import { getUTMHeaders } from '@/utils/analytics'
import { HttpMethod } from '@/constants/utils'
import { getToken } from '@/store/selectors'
import { isProd, activeProduct, PRODUCT } from '@/utils'
import type { RootState } from '@/store'

import { getRegion } from './region'
import { getAccountsApiUrl, getProductApiUrl } from './urls'
import type {
  IFetchResult,
  IResponse,
  ICreateProductApiRequest,
  ICreateDpsApiRequest,
  ICreateApiRequestBase,
  ICustomBaseQueryArgs,
  IApiError,
} from './interfaces'

export function* waitForState(
  selector: Selector,
  ...args: unknown[]
): SagaIterator {
  do {
    const state = yield select(selector, ...args)

    if (typeof state !== 'undefined') {
      return state
    }
    yield take('*')
  } while (true)
}

export function* createApiRequest<RT, T = void>({
  method,
  path,
  body,
  noauth = false,
  multipart = false,
  tokenRequired = true,
}: ICreateDpsApiRequest<T>): SagaIterator<IResponse<RT> | void> {
  let token

  try {
    if (noauth) {
      token = undefined
    } else {
      if (tokenRequired) {
        token = yield call(waitForState, getToken)
      } else {
        token = yield select(getToken) || undefined
      }
    }

    const headers = new Headers({
      ...(body && !multipart && { 'Content-Type': 'application/json' }),
      ...(token && { Authorization: 'Bearer ' + token }),
      ...getUTMHeaders(),
    })

    const config: RequestInit = {
      method,
      headers,
      body: body
        ? multipart
          ? (body as BodyInit)
          : JSON.stringify(body)
        : null,
    }

    const response: IFetchResult = yield call(
      fetch,
      process.env.GATSBY_DEV_PLATFORM_URL_V2 + path,
      config
    )

    const json =
      response?.status !== 204
        ? yield call(() => {
            return response.text().then((text) => {
              if (response.status === 404) {
                return text
              }

              if (text) {
                try {
                  const parsedText = JSON.parse(text)
                  return parsedText
                } catch (e) {
                  return text
                }
              } else {
                return {}
              }
            })
          })
        : undefined

    return { status: response.status, error: response?.error, json }
  } catch (err) {
    if (!isProd) {
      if (err instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(err?.message)
      }
    }
  }
}

export function* createProductApiRequest<T>({
  product = activeProduct,
  method = HttpMethod.GET,
  path,
  body,
  noauth = false,
}: ICreateProductApiRequest<T>): SagaIterator {
  const productApiUrl = getProductApiUrl(product)

  const token = noauth
    ? undefined
    : yield call(waitForState, (state) => state.user.data?.token)

  const headers = new Headers({
    ...(token && { Authorization: 'Bearer ' + token }),
  })

  const config: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }

  if (token) {
    const region = getRegion(token)

    if (region) {
      headers.set('X-Region', region)
    }
  }

  const response: IFetchResult = yield call(() => {
    return fetch(productApiUrl + path, config)
  })

  const json =
    response.status !== 204 ? yield call(() => response.json()) : undefined

  return { status: response.status, error: response.error, json }
}

export function* createAccountsApiRequest<T>({
  product = PRODUCT.LiveChat,
  method = HttpMethod.GET,
  path,
  body,
  noauth = false,
}: ICreateProductApiRequest<T>): SagaIterator {
  const accountsApiUrl = getAccountsApiUrl(product)

  const token = noauth
    ? undefined
    : yield call(waitForState, (state) => state.user.data?.token)

  const headers = new Headers({
    ...(token && { Authorization: 'Bearer ' + token }),
  })

  const config: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }
  const response = yield call(() => {
    return fetch(accountsApiUrl + path, config)
  })

  const json =
    response.status !== 204 ? yield call(() => response.json()) : undefined

  return { status: response.status, error: response.error, json }
}

export function* createBillingApiRequest<T>({
  method,
  path,
  body,
  noauth = false,
}: ICreateApiRequestBase<T>): SagaIterator {
  const token = noauth
    ? undefined
    : yield call(waitForState, (state) => state.user.data?.token)

  const headers = new Headers({
    ...(token && { Authorization: 'Bearer ' + token }),
  })

  const config: RequestInit = {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }

  const response = yield call(() => {
    return fetch(process.env.GATSBY_BILLING_API_URL + path, config)
  })

  const { status } = response

  const json =
    (status >= 200 && status < 300) || status === 422
      ? yield call(() => response.json())
      : undefined

  return { status, error: response.error, json }
}

export const customFetchBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: '' }
  ): BaseQueryFn<ICustomBaseQueryArgs, unknown, unknown> =>
  async ({ path, method = HttpMethod.GET, body, noauth }, { getState }) => {
    const token = (getState() as RootState).user.data?.token as string | null

    const headers = new Headers({
      ...(!!body && { 'Content-Type': 'application/json' }),
      ...(token && !noauth && { Authorization: 'Bearer ' + token }),
    })

    const config: RequestInit = {
      method,
      headers,
      ...(!!body && { body: JSON.stringify(body) }),
    }

    try {
      const response = await fetch(baseUrl + path, config)
      const result = await response.json()

      if (!response.ok) {
        throw new Error(`Request failed with status: [${response.status}]`)
      }

      return { data: result }
    } catch (error) {
      const apiError = error as IApiError

      if ('code' in apiError) {
        return { error: apiError.code }
      }

      if ('error' in apiError) {
        return { error: apiError.error }
      }

      return {
        error: 'Unexpected error',
      }
    }
  }
