import { createSlice, createAction } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isSendingMessage: false,
  sendingMessageSuccess: false,
  isSendingComment: false,
  sendingCommentSuccess: false,
  error: null,
}

export const sendExpertVisitRequest = createAction('experts/visitRequest')
export const sendExpertComment = createAsyncActions('experts/comment')
export const sendExpertMessage = createAsyncActions('experts/message')

// TODO: Remove this and convert actions to react-query
const expertsSlice = createSlice({
  name: 'experts',
  initialState,
  reducers: {
    clearExpertState() {
      return initialState
    },
  },
  extraReducers: {
    [sendExpertComment.pending]: (state) => {
      state.isSendingComment = true
      state.sendingCommentSuccess = false
      state.error = null
    },
    [sendExpertComment.fulfilled]: (state, { payload }) => {
      state.isSendingComment = false
      state.sendingCommentSuccess = true
    },
    [sendExpertComment.rejected]: (state, { payload }) => {
      state.isSendingComment = false
      state.error = payload?.error
    },
    [sendExpertMessage.pending]: (state) => {
      state.isSendingMessage = true
      state.sendingMessageSuccess = false
      state.error = null
    },
    [sendExpertMessage.fulfilled]: (state, { payload }) => {
      state.isSendingMessage = false
      state.sendingMessageSuccess = true
    },
    [sendExpertMessage.rejected]: (state, { payload }) => {
      state.isSendingMessage = false
      state.error = payload?.error
    },
  },
})

export const { clearExpertState } = expertsSlice.actions
export const reducer = expertsSlice.reducer
