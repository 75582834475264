import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import { middleware as accountsApiMiddleware } from '@/apis/accounts/slice'
import { middleware as appsApiMiddleware } from '@/apis/apps/slice'
import { isProd } from '@/utils'
import rootReducer from './reducers'
import rootSaga from './sagas'

const configureAppStore = (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      sagaMiddleware,
      accountsApiMiddleware,
      appsApiMiddleware,
    ],
    preloadedState,
  })

  sagaMiddleware.run(rootSaga)

  if (!isProd && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }

  return store
}

export const store = configureAppStore()

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
