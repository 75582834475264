import { SCOPES } from '@/constants/utils'
import {
  isCampaignsFetching,
  isCartInitialized,
  isPublishedAppsFetched,
  isSubscriptionFetched,
} from '../selectors'

export const getUser = (state) => state.user || {}
export const getUserData = (state) => getUser(state)?.data
export const getUserPermissions = (state) => getUser(state)?.permissions

export const isAuthorized = (state) => getUser(state)?.isAuthorized
export const isResellerLicense = (state) => getUser(state)?.isReseller
export const isGhostLogin = (state) => getUser(state)?.isGhostLogin

export const isFetchingUser = (state) => getUser(state)?.isFetching
export const isUserInitialized = (state) => getUser(state)?.isInitialized
// TODO: remove this function and use isInitialized instead
export const isUserDataFetched = (state) => isUserInitialized(state)

// User's data
export const getUserAvatar = (state) => getUserData(state)?.avatar || ''
export const getUserEmail = (state) => getUserData(state)?.email || ''
export const getUserName = (state) => getUserData(state)?.name || ''

export const getToken = (state) => getUserData(state)?.token
export const hasToken = (state) => !!getToken(state)
export const getAccountId = (state) => getUserData(state)?.accountId
export const getUserOrganization = (state) => getUserData(state)?.organizationId

export const isUserRelatedDataFetched = (state) => {
  const hasPublishedAppsFetched = isPublishedAppsFetched(state)
  const hasCampaignsFetched = !isCampaignsFetching(state)
  const hasSubscriptionFetched = isSubscriptionFetched(state)
  const hasCartInitialized = isCartInitialized(state)

  return (
    hasPublishedAppsFetched &&
    hasCampaignsFetched &&
    hasSubscriptionFetched &&
    hasCartInitialized
  )
}

// Permissions
export const hasScope = (state, scope) =>
  getUserPermissions(state)?.includes(scope)

export const canBuy = (state) =>
  (hasScope(state, SCOPES.BILLING_ADMIN) &&
    hasScope(state, SCOPES.BILLING_MANAGE)) ||
  hasScope(state, SCOPES.BILLING_ALL)
export const canInstallApplications = (state) =>
  hasScope(state, SCOPES.INTEGRATIONS_MANAGE) ||
  hasScope(state, SCOPES.HD_INTEGRATIONS_MANAGE)
export const canManageProduct = (state) =>
  hasScope(state, SCOPES.ORGANIZATION_MANAGE) ||
  hasScope(state, SCOPES.PRODUCT_ORGANIZATION_MANAGE)
export const isDevPlatformAdmin = (state) =>
  hasScope(state, SCOPES.DEV_PLATFORM_ADMIN)
