import { createApi } from '@reduxjs/toolkit/query/react'

import { customFetchBaseQuery } from '@/utils/api'
import { PRODUCT } from '@/utils'
import { IGetPricePreviewParams, IGetPricePreviewResponse } from './interfaces'

export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery: customFetchBaseQuery({
    baseUrl: process.env.GATSBY_BILLING_API_URL,
  }),
  endpoints: ({ query }) => ({
    getPricePreview: query<IGetPricePreviewResponse, IGetPricePreviewParams>({
      query: ({ price, productType = PRODUCT.LiveChat }) => {
        const urlParams = new URLSearchParams({
          price: `${price}`,
        })

        return { path: `/price_preview/${productType}?${urlParams}` }
      },
    }),
  }),
})

export const { useGetPricePreviewQuery, reducer, reducerPath, middleware } =
  billingApi
