export const ignoreErrors = [
  /Loading chunk [0-9]+ failed/,
  /JSONP request failed./,
  /We couldn't load ".*\/page-data\/.*\.json"/,
  /Non-Error promise rejection captured with value: Object Not Found Matching Id:[0-9]+/,
  /Failed to fetch/,
  /Illegal invocation/,
  /Invalid attempt to spread non-iterable instance. In order to be iterable, non-array objects must have a \[Symbol.iterator\]\(\) method./,
  /Can't find variable: Audio/,
  /e.toggleAttribute is not a function/,
  /Unexpected end of JSON input/,
  /Error: Unable to deserialize cloned data due to invalid or unsupported version./,
  /Failed to execute 'getBattery' on 'Navigator': Illegal invocation/,
  /Cannot convert 'window.sessionStorage' to object/,
  /Undefined variable: MutationObserver/,
]
