import { REGION } from '@/constants/utils'

const supportedRegions = Object.values(REGION)

export const getRegion = (token) => {
  const region = token.split(':')[0]

  if (supportedRegions.includes(region)) {
    return region
  }

  return undefined
}
