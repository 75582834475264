import { call, put, takeEvery } from 'redux-saga/effects'

import { GET, OK } from '@/constants/utils'
import { createApiRequest } from '@/utils/api'
import { fetchPrivateApps } from '@/store/privateApps'
import { userLogIn, userLogOut } from '@/store/user'

function* requestAppList() {
  try {
    const { status, json } = yield call(createApiRequest, {
      mathod: GET,
      path: '/applications',
    })

    if (status === OK) {
      yield put(
        fetchPrivateApps.fulfilled({
          items: json.reduce((prev, next, i) => {
            prev[next.id] = next
            return prev
          }, {}),
        })
      )
    } else {
      throw new Error("Apps list couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchPrivateApps.rejected({ error: error?.message }))
  }
}

export function* requestsOnLoginSuccess() {
  yield put(fetchPrivateApps.pending())
}

export function* requestsOnLogoutSuccess() {
  // Reset private apps
  yield put(
    fetchPrivateApps.fulfilled({
      items: {},
    })
  )
}

export function* watchPrivateAppsRequests() {
  yield takeEvery(fetchPrivateApps.pending, requestAppList)
  yield takeEvery(userLogIn.fulfilled, requestsOnLoginSuccess)
  yield takeEvery(userLogOut.fulfilled, requestsOnLogoutSuccess)
}
