import { sortByOrder } from './sorts'

export const reorderSearch = (items) => {
  const nameFoundItems = []
  const shortDescriptionFoundItems = []
  const longDescriptionFoundItems = []
  const categoryNameFoundItems = []
  const unknownItems = []

  items.forEach((item) => {
    if (item?.['_highlightResult']?.name?.matchedWords?.length) {
      nameFoundItems.push(item)
    } else if (
      item?.['_highlightResult']?.shortDescription?.matchedWords?.length
    ) {
      shortDescriptionFoundItems.push(item)
    } else if (
      item?.['_highlightResult']?.longDescription?.matchedWords?.length
    ) {
      longDescriptionFoundItems.push(item)
    } else if (
      item?.['_highlightResult']?.category?.name?.matchedWords?.length
    ) {
      categoryNameFoundItems.push(item)
    } else {
      unknownItems.push(item)
    }
  })

  nameFoundItems.sort(sortByOrder)
  shortDescriptionFoundItems.sort(sortByOrder)
  longDescriptionFoundItems.sort(sortByOrder)
  categoryNameFoundItems.sort(sortByOrder)

  const reorderedItems = [
    ...nameFoundItems,
    ...shortDescriptionFoundItems,
    ...longDescriptionFoundItems,
    ...categoryNameFoundItems,
    ...unknownItems,
  ]

  return reorderedItems
}
