const ENVS = {
  LABS: 'labs',
  DEVELOPMENT: 'development',
  PREVIEW: 'preview',
  TEST: 'test',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

const PRODUCT = {
  LiveChat: 'livechat',
  HelpDesk: 'helpdesk',
  Accounts: 'accounts',
  ChatBot: 'chatbot',
  Text: 'text',
}

const MARKETPLACE_VERSION = {
  BASE: 'BASE',
  PUBLIC: 'PUBLIC',
  AGENT_APP: 'AGENT_APP',
  HELP_DESK_PUBLIC: 'HELP_DESK_PUBLIC',
  HELP_DESK_IFRAME: 'HELP_DESK_IFRAME',
  TEXT_PUBLIC: 'TEXT_PUBLIC',
  TEXT_IFRAME: 'TEXT_IFRAME',
}

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || ENVS.DEVELOPMENT
const activeDotenv = `.env.${
  activeEnv === ENVS.PREVIEW ? ENVS.DEVELOPMENT : activeEnv
}`

// BUILD ENVIRONMENTS
const isProd = activeEnv === ENVS.PRODUCTION
const isStaging = activeEnv === ENVS.STAGING
const isDev = activeEnv === ENVS.DEVELOPMENT || activeEnv === ENVS.PREVIEW
const isLabs = activeEnv === ENVS.LABS
const isPreview = activeEnv === ENVS.PREVIEW
const isTestEnv = process.env.NODE_ENV === ENVS.TEST

// MARKETPLACE VERSIONS
const isBase =
  process.env.GATSBY_MARKETPLACE_VERSION === MARKETPLACE_VERSION.BASE
const isPublic =
  process.env.GATSBY_MARKETPLACE_VERSION === MARKETPLACE_VERSION.PUBLIC
const isAgentApp =
  process.env.GATSBY_MARKETPLACE_VERSION === MARKETPLACE_VERSION.AGENT_APP
const isHelpDeskPublic =
  process.env.GATSBY_MARKETPLACE_VERSION ===
  MARKETPLACE_VERSION.HELP_DESK_PUBLIC
const isHelpDeskIFrame =
  process.env.GATSBY_MARKETPLACE_VERSION ===
  MARKETPLACE_VERSION.HELP_DESK_IFRAME
const isTextPublic =
  process.env.GATSBY_MARKETPLACE_VERSION === MARKETPLACE_VERSION.TEXT_PUBLIC
const isTextIFrame =
  process.env.GATSBY_MARKETPLACE_VERSION === MARKETPLACE_VERSION.TEXT_IFRAME
const isLiveChat = isPublic || isAgentApp || isBase
const isHelpDesk = isHelpDeskPublic || isHelpDeskIFrame
const isText = isTextPublic || isTextIFrame
const isIframeVersion = isAgentApp || isHelpDeskIFrame || isTextIFrame
const isPublicVersion = isPublic || isHelpDeskPublic || isTextPublic
const activeProduct = isHelpDesk ? PRODUCT.HelpDesk : PRODUCT.LiveChat

/* 
  Export made with CommonJS pattern so we could use it inside of gatsby
  specific files like gatsby-node.js which cannot use ES6 imports
*/
module.exports = {
  PRODUCT,
  ENVS,
  activeProduct,
  activeEnv,
  activeDotenv,
  isProd,
  isStaging,
  isDev,
  isLabs,
  isPreview,
  isTestEnv,
  isPublic,
  isAgentApp,
  isHelpDeskPublic,
  isHelpDeskIFrame,
  isTextPublic,
  isTextIFrame,
  isLiveChat,
  isHelpDesk,
  isText,
  isIframeVersion,
  isPublicVersion,
}
