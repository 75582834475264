import { PRODUCT_STATUS } from '@/constants/utils'

export const calculateProductStatus = ({
  submittedAt,
  acceptedAt,
  publishedAt,
}) => {
  if (submittedAt && acceptedAt && publishedAt) {
    return PRODUCT_STATUS.PUBLISHED
  } else if (submittedAt && acceptedAt) {
    return PRODUCT_STATUS.ACCEPTED
  } else if (submittedAt) {
    return PRODUCT_STATUS.IN_REVIEW
  }

  return PRODUCT_STATUS.IN_DEVELOPMENT
}
