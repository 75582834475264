import { createBillingApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'
import { PRODUCT } from '@/utils'

const read = (id: string, productType = PRODUCT.LiveChat) =>
  createBillingApiRequest({
    method: HttpMethod.GET,
    path: `/direct_charge/${productType}/${id}`,
  })

const readAll = (order_client_id: string, productType = PRODUCT.LiveChat) =>
  createBillingApiRequest({
    method: HttpMethod.GET,
    path: `/direct_charge/${productType}?order_client_id=${order_client_id}`,
  })

const create = (
  name: string,
  price: number,
  quantity = 1,
  returnUrl: string,
  clientId: string,
  perAccount: boolean,
  productType: string,
  test = false
) => {
  const appUrl = `${window.location.origin}/${
    process.env.GATSBY_PATH_PREFIX ? process.env.GATSBY_PATH_PREFIX + '/' : ''
  }`

  return createBillingApiRequest({
    method: HttpMethod.POST,
    path: `/direct_charge/${productType}`,
    body: {
      name,
      price,
      quantity,
      return_url: returnUrl || appUrl,
      order_client_id: clientId,
      per_account: perAccount,
      ...(test && { test }),
    },
  })
}

const activate = (id: string, productType = PRODUCT.LiveChat) =>
  createBillingApiRequest({
    method: HttpMethod.PUT,
    path: `/direct_charge/${productType}/${id}/activate`,
  })

const accept = (id: string, productType = PRODUCT.LiveChat) =>
  createBillingApiRequest({
    method: HttpMethod.PUT,
    path: `/direct_charge/${productType}/${id}/accept`,
  })

const decline = (id: string, productType = PRODUCT.LiveChat) =>
  createBillingApiRequest({
    method: HttpMethod.PUT,
    path: `/direct_charge/${productType}/${id}/decline`,
  })

export default {
  read,
  readAll,
  activate,
  create,
  accept,
  decline,
}
