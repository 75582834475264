import type { IPaymentCartItem } from '@/store/payment/interfaces'
import { PRODUCT } from '@/utils/environment'
import { CART_ITEM } from '@/constants'

export interface IProductTypeCartItemList {
  productType: string
  cartItems: IPaymentCartItem[]
}

/**
 * Splits cart items into lists on per `productType` basis
 */
export const getProductTypeCartItemLists = (items: IPaymentCartItem[]) => {
  const itemsLists = items?.reduce<IProductTypeCartItemList[]>(
    (lists, cartItem) => {
      const productList = lists.find(
        (product) => product?.productType === cartItem?.productType
      )
      if (!productList) {
        return [
          ...lists,
          {
            productType: cartItem.productType || PRODUCT.LiveChat,
            cartItems: [cartItem],
          },
        ]
      } else {
        productList.cartItems = [...productList.cartItems, cartItem]
        return lists
      }
    },
    []
  )

  return itemsLists
}

/**
 * Given cart items and inactive license, splits the items
 * into lists of installedItems and inactiveLicenseItems,
 * each of them pertaining to a `productType`.
 */
export const getInactiveLicenseCartItemLists = (
  items: IPaymentCartItem[],
  inactiveLicense?: string
) => {
  const productItemLists = getProductTypeCartItemLists(items)
  const installedItemLists: IProductTypeCartItemList[] = []
  const inactiveLicenseItemLists: IProductTypeCartItemList[] = []

  productItemLists?.forEach((list) => {
    if (inactiveLicense === list.productType) {
      inactiveLicenseItemLists.push(list)
    } else {
      installedItemLists.push(list)
    }
  })

  return [installedItemLists, inactiveLicenseItemLists]
}

export const isEveryErrorInactiveProduct = (notes: string[]) =>
  !notes.some((note) => note !== CART_ITEM.NOTE.DPS_ERROR.PRODUCT_INACTIVE)
