import colors from '@livechat/design-system-colors'
import {
  DesignToken,
  RadiusToken,
  ShadowToken,
  SpacingToken,
  TransitionDelayToken,
  TransitionDurationToken,
  TransitionTimingToken,
} from '@livechat/design-system-react-components'

const marketplaceTheme = {
  fontFamily: 'Colfax, "Source Sans Pro", sans-serif',
  fontSize: '18px',
  layout: {
    page: {
      horizontalSpacing: '32px',
    },
    breadcrumbs: {
      borderColor: colors.gray50,
    },
  },
  spacing: {
    s: '16px',
    m: '32px',
    l: '36px',
    xl: '60px',
  },
  font: {
    bold: 500,
  },
  colors: {
    primary: '#424d57',
    primaryAlpha: (alpha: number) => `rgba(66, 77, 87, ${alpha})`,
    secondary: '#677179',
    secondaryAlpha: (alpha: number) => `rgba(103, 113, 121, ${alpha})`,
    tertiary: '#8e949a',
    tertiaryAlpha: (alpha: number) => `rgba(142, 148, 154, ${alpha})`,
    placeholder: `#c5c9cc`,
    placeholderAlpha: (alpha: number) => `rgba(197, 201, 204, ${alpha})`,

    error: '#f4574c',
    success: '#4bb678',
    warning: '#efa842',

    black: '#1b1b20',
    blackHover: '#4a4a56',
    blackDisabled: '#ababb0',

    yellow: '#ffd000',
    yellowHover: '#f0c400',
    yellowDisabled: '#fbe9ab',

    blue: '#1128f5',
    blueHover: '#1400b4',
    blueDisabled: '#929dfa',

    blueRibbon: '#0066FF',

    red: '#ff2b00',
    redHover: '#c1002b',
    redDisabled: '#ff9e8b',

    borderSubtle: '#E4E8EC',
    borderGray: '#CFD3D5',
    grayDivider: '#DDE2E6',
    grey: '#f6f6f7',
    white: '#ffffff',
    whiteHover: '#f9fbfb',
    gold: '#ffd000',
    rose: '#f10f60',
    iron: '#d4d4d7',
    jumbo: '#767581',
    cornflowerBlue: '#4384f5',
    cornflowerBlueHover: '#4379D6',
    catskillWhite: '#f1f6f8',
    cornsilk: '#fff8e1',
    mystic: '#e6ebef',
    aquaHaze: '#F4F7F9',
    lowEmphasisPurpleAccent: '#EEE8FF',
    lowEmphasisPositive: '#D1F4D5',
    lowEmphasisWarning: '#FFE9BC',
    geyserAlpha: (alpha: number) => `rgba(205, 216, 224, ${alpha})`,
    blackAlpha: (alpha: number) => `rgba(27, 27, 32, ${alpha})`,

    gray40: colors.gray25,
    gray50: colors.gray50,
    gray100: colors.gray100,
    gray200: colors.gray200,
    gray500: colors.gray500,
    gray600: colors.gray600,
    gray700: colors.gray700,
    gray900: colors.gray900,

    // TODO: Update @livechat/design-system-colors so we could use colors from design system lib
    black30: '#F6F6F7',
    black40: '#EEEEEF',
    black50: '#E2E2E4',
    black100: '#C9C9CD',
    black500: '#62626D',
    black600: '#4E4E58',
    black700: '#3B3B43',
    black800: '#29292F',
    black900: '#1B1B20',
    black950: '#131317',

    blue100: '#DAEDFF',
    blue500: colors.blue500,
    blue600: colors.blue600,
    blue700: colors.blue700,
    blue800: '#003FA4',

    green50: colors.green50,
    green100: '#E4F4ED',
    green300: colors.green300,
    green400: colors.green400,
    green500: colors.green500,
    green600: colors.green600,

    red500: colors.red500,

    orange500: colors.orange500,
    orange600: colors.orange600,
  },
  boxShadows: {
    menu: '0px 1px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.1)',
  },
  maxWidth: '1376px',
  header: {
    height: '90px',
  },
}

type TokenType =
  | typeof DesignToken
  | typeof SpacingToken
  | typeof RadiusToken
  | typeof ShadowToken
  | typeof TransitionDurationToken
  | typeof TransitionTimingToken
  | typeof TransitionDelayToken

const withVar = (token: string) => `var(${token})`

const reduceToken = <T extends TokenType>(
  token: T
): { [K in keyof T]: string } =>
  Object.entries(token).reduce((acc, [property, value]) => {
    return { ...acc, [property]: withVar(value) }
  }, {} as { [K in keyof T]: string })

const themedTokens = {
  DesignToken: reduceToken(DesignToken),
  SpacingToken: reduceToken(SpacingToken),
  RadiusToken: reduceToken(RadiusToken),
  ShadowToken: reduceToken(ShadowToken),
  TransitionDurationToken: reduceToken(TransitionDurationToken),
  TransitionTimingToken: reduceToken(TransitionTimingToken),
  TransitionDelayToken: reduceToken(TransitionDelayToken),
}

const theme = {
  ...marketplaceTheme,
  ...themedTokens,
}

export default theme
