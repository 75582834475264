import { spawn } from 'redux-saga/effects'
import { put, call, takeEvery } from 'redux-saga/effects'
import * as FullStory from '@fullstory/browser'

import { notificationsCreate } from '@/store/notifications'
import { userLogIn, userLogOut, checkIfUserIsReseller } from '@/store/user'
import { fetchCampaigns, fetchPublicCampaigns } from '@/store/campaigns'
import { fetchSubscription } from '@/store/subscription'
import { fetchPublishedApps } from '@/store/publishedApps'
import { create as createCart } from '@/store/cart'
import { PRODUCT, customNavigate } from '@/utils'
import { OK } from '@/constants/utils'
import { NOTIFICATIONS, SENTRY } from '@/constants'
import authSessionsApi from '@/apis/accounts/sessions'
import { getAccount, getTokenInfo } from '@/apis/accounts/accounts'
import auth from '@/utils/auth'
import { sendPostMessage, PM_EVENT } from '@/utils/browser'
import { createProductApiRequest } from '@/utils/api'
import { isProd, isLiveChat } from '@/utils/environment'
import { errorHandler } from '@/utils/sagas'

const getUserMetaData = (user) => {
  if (!user || typeof user !== 'object') {
    throw new Error("Incorrect user object, cannot retrieve user's meta data")
  }

  return {
    name: user?.name,
    avatar: user?.avatar_url,
    email: user?.email,
  }
}

function* loginUser({
  payload: { scope, token, licenseId, organizationId, accountId, isGhostLogin },
}) {
  try {
    if (!token) {
      throw new Error('Missing token')
    }

    if (!accountId) {
      const tokenInfoResponse = yield call(getTokenInfo)

      if (tokenInfoResponse?.status !== OK) {
        throw new Error('Cannot fetch token info')
      }

      organizationId = tokenInfoResponse?.json?.organization_id
      accountId = tokenInfoResponse?.json?.account_id
      licenseId = tokenInfoResponse?.json?.license_id
    }

    const userResponse = yield call(getAccount, accountId)

    if (userResponse?.status !== OK) {
      throw new Error('Cannot fetch user data')
    }

    const permissions = scope?.split(',') || []
    const userData = getUserMetaData(userResponse?.json)

    yield put(
      userLogIn.fulfilled({
        token,
        accountId,
        organizationId,
        licenseId,
        isGhostLogin,
        permissions,
        ...userData,
      })
    )

    if (isGhostLogin) {
      yield call(handleGhostLogin)
    }

    const fullStoryIdentity = licenseId || organizationId
    if (isProd && fullStoryIdentity) {
      FullStory.identify(fullStoryIdentity, {
        displayName: fullStoryIdentity,
        email: userData?.email,
      })
    }
  } catch (error) {
    yield put(userLogIn.rejected({ error: error?.message }))
    yield spawn(errorHandler, error, SENTRY.TAGS.USER)
  }
}

function* handleGhostLogin() {
  const {
    status: tokenStatus,
    json: { account_id },
  } = yield call(getTokenInfo)

  if (tokenStatus === OK) {
    const {
      status: accountStatus,
      json: { email },
    } = yield call(getAccount, account_id)

    if (accountStatus === OK) {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.ERROR,
          content: `Browsing as ${email}`,
          action: {
            handler: () => {
              window.location = window.location.href.split('#')[0]
            },
            label: 'Stop now',
          },
        })
      )
    } else {
      yield put(
        notificationsCreate({
          variant: NOTIFICATIONS.VARIANTS.ERROR,
          content: `Ghost login failed! Please contact support.`,
          delay: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        })
      )
    }
  }
}

function* logoutUser() {
  try {
    auth.signOut()
    const { status } = yield call(authSessionsApi.deleteAll)

    if (status === OK) {
      yield put(userLogOut.fulfilled())
      yield call(customNavigate, '/')
    }
  } catch (error) {
    yield put(userLogOut.rejected({ error: error?.message }))
  }
}

function* requestLiveChatLicenseProperties() {
  try {
    const { status, json } = yield call(createProductApiRequest, {
      product: PRODUCT.LiveChat,
      path: 'properties/license',
    })

    if (status === OK) {
      const isReseller = json?.partner_type === 'reseller'
      yield put(checkIfUserIsReseller.fulfilled({ isReseller }))
    } else {
      throw new Error("Couldn't fetch information about license")
    }
  } catch (error) {
    yield put(checkIfUserIsReseller.rejected({ error: error?.message }))
  }
}

function* notifyParent() {
  yield call(() => {
    sendPostMessage(PM_EVENT.LOADED)
  })
}

function* fetchUserRelatedData() {
  yield put(fetchPublishedApps.pending())
  yield put(fetchCampaigns.pending())
  yield put(createCart.pending())
  yield put(fetchSubscription.pending())

  if (isLiveChat) {
    yield put(checkIfUserIsReseller.pending())
  }
}

function* fetchPublicData() {
  yield put(fetchPublishedApps.pending())
  yield put(fetchPublicCampaigns.pending())
}

export function* watchUserRequests() {
  yield takeEvery(userLogIn.pending, loginUser)
  yield takeEvery(userLogIn.fulfilled, notifyParent)
  yield takeEvery(userLogIn.fulfilled, fetchUserRelatedData)
  yield takeEvery(userLogIn.rejected, fetchPublicData)
  yield takeEvery(userLogOut.pending, logoutUser)
  yield takeEvery(
    checkIfUserIsReseller.pending,
    requestLiveChatLicenseProperties
  )
}
