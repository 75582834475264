import React, { PropsWithChildren, useEffect, useState } from 'react'
import { HydrationContext } from '@/context/hydration'

const HydrationProvider = ({ children }: PropsWithChildren) => {
  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    setHydrated(true)
  }, [])

  return (
    <HydrationContext.Provider value={hydrated}>
      {children}
    </HydrationContext.Provider>
  )
}

export default HydrationProvider
