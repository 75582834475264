import React from 'react'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch'
import { SEARCH } from '../constants'

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
)

const searchClient = {
  // Avoid initial blank searches, min search for 2 characters
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => {
      return query.length >= SEARCH.MIN_CHARS
    })

    if (shouldSearch) {
      return algoliaClient.search(requests)
    }

    return Promise.resolve({
      results: [{ hits: [] }],
    })
  },
}

const AlgoliaProvider = props => {
  const { children } = props

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
    >
      <Configure hitsPerPage={SEARCH.LIMIT} filters={'hidden:false'} />
      {children}
    </InstantSearch>
  )
}

export default AlgoliaProvider
