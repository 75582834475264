/* eslint-disable import/no-anonymous-default-export */
import { createProductApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'
import { PRODUCT } from '@/utils'

import type {
  IExpertBaseArgs,
  IExpertMessageArgs,
  IExpertCommentArgs,
  IExpertVisitArgs,
} from './interfaces'

export const getExpertDetails = (id: Pick<IExpertBaseArgs, 'id'>) =>
  createProductApiRequest({
    method: HttpMethod.GET,
    path: `partners/experts_marketplace/${id}`,
    body: null,
    noauth: true,
    product: PRODUCT.LiveChat,
  })

export const sendExpertMessage = ({
  id,
  email,
  userEmail,
  message,
  name,
  surname,
  website,
}: IExpertMessageArgs) =>
  createProductApiRequest({
    method: HttpMethod.POST,
    path: `partners/experts_marketplace/${id}/message`,
    body: { email, userEmail, message, name, surname, website },
    noauth: true,
    product: PRODUCT.LiveChat,
  })

export const sendExpertComment = ({
  id,
  email,
  userEmail,
  review,
  name,
  website,
}: IExpertCommentArgs) =>
  createProductApiRequest({
    method: HttpMethod.POST,
    path: `partners/experts_marketplace/${id}/comment`,
    body: { email, userEmail, comment: review, name, website },
    noauth: true,
    product: PRODUCT.LiveChat,
  })

export const sendExpertVisit = ({
  id,
  timestamp = new Date().toISOString(),
}: IExpertVisitArgs) =>
  createProductApiRequest({
    method: HttpMethod.POST,
    path: `partners/experts/visits`,
    body: { expert_id: id, visited_at: timestamp },
    noauth: true,
    product: PRODUCT.LiveChat,
  })

export default {
  getExpertDetails,
  sendExpertMessage,
  sendExpertComment,
  sendExpertVisit,
}
