import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

export const initialState = {
  items: [],
  isFetching: false,
  error: null,
}

export const readDirectCharge = createAsyncActions('directCharge/read')
export const createDirectCharge = createAsyncActions('directCharge/create')
export const acceptDirectCharge = createAsyncActions('directCharge/accept')
export const declineDirectCharge = createAsyncActions('directCharge/decline')
export const activateDirectCharge = createAsyncActions('directCharge/activate')

const directChargesSlice = createSlice({
  name: 'directCharge',
  initialState,
  reducers: {
    directChargePollStart(state) {
      state.isFetching = true
    },
    directChargePollStop(state) {
      state.isFetching = false
    },
    archiveDirectCharge(state, { payload }) {
      const itemId = payload?.json?.id || payload?.item?.id
      state.items = state.items.filter((item) => item.id !== itemId)
    },
  },
  extraReducers: {
    [acceptDirectCharge.pending]: (state) => {
      state.isFetching = true
    },
    [acceptDirectCharge.fulfilled]: (state) => {
      state.isFetching = false
    },
    [acceptDirectCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [declineDirectCharge.pending]: (state) => {
      state.isFetching = true
    },
    [declineDirectCharge.fulfilled]: (state) => {
      state.isFetching = false
    },
    [declineDirectCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [activateDirectCharge.pending]: (state) => {
      state.isFetching = true
    },
    [activateDirectCharge.fulfilled]: (state, { payload }) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === payload?.item?.id
      )

      state.isFetching = false
      state.items[itemIndex] = payload.item
    },
    [activateDirectCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [createDirectCharge.pending]: (state) => {
      state.isFetching = true
    },
    [createDirectCharge.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items.push(payload?.item)
    },
    [createDirectCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [readDirectCharge.pending]: (state) => {
      state.isFetching = true
    },
    [readDirectCharge.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items.push(payload?.item)
    },
    [readDirectCharge.rejected]: (state) => {
      state.isFetching = false
    },
  },
})

export const {
  directChargePollStart,
  directChargePollStop,
  archiveDirectCharge,
} = directChargesSlice.actions
export const reducer = directChargesSlice.reducer
