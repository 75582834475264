import { call, put, takeEvery } from 'redux-saga/effects'

import { getMarketplaceSourceUrl } from '@/utils/analytics'
import { canUseWindow, filterOutParams, getUrlParams } from '@/utils/browser'
import { logAmplitudeEvent, utmParameters } from '@/store/stats'
import { userLogIn } from '@/store/user'
import { SUPPORTED_UTM_PARAMETERS } from '@/constants'

let amplitude
if (canUseWindow) {
  amplitude = require('amplitude-js')
}

function requestLogAmplitudeEvent({ payload }) {
  const { name, params = {} } = payload

  const locationParams = {
    ...(canUseWindow && { path: window.location.pathname }),
    source_url: getMarketplaceSourceUrl(),
  }

  amplitude.logEvent(name, { ...params, ...locationParams })
}

function setUser({ payload }) {
  if (payload?.email) {
    amplitude.getInstance().setUserId(payload.email)
  }
}

export function* watchStatsRequests() {
  const params = getUrlParams()
  const utmParams = filterOutParams(
    params,
    Object.values(SUPPORTED_UTM_PARAMETERS)
  )

  yield put(utmParameters(utmParams))

  if (amplitude && process.env.GATSBY_AMPLITUDE_KEY) {
    const productId = process.env.GATSBY_PRODUCT_ID || ''
    const identify = new amplitude.Identify().setOnce('productId', productId)

    yield call(() => {
      new Promise((resolve) => {
        amplitude.getInstance().init(
          process.env.GATSBY_AMPLITUDE_KEY,
          null,
          {
            includeUtm: true,
          },
          resolve
        )
      })
    })

    yield call(() => {
      amplitude.getInstance().identify(identify)
    })

    yield takeEvery(userLogIn.fulfilled, setUser)
    yield takeEvery(logAmplitudeEvent, requestLogAmplitudeEvent)
  }
}
