import { canUseWindow } from '@/utils/browser'

const linkToChat = 'https://direct.lc.chat/1520/'

export const openChat = () => {
  const LC_API = window.LC_API || {}

  if (typeof LC_API.open_chat_window !== 'undefined') {
    LC_API.open_chat_window()
  } else {
    window.open(linkToChat, '_blank')
  }
}

export const handleChatWindow = (hide) => {
  if (canUseWindow) {
    if (!window.LC_API) {
      window.LC_API = {
        is_loaded: () => false,
      }
    }
    const { LC_API } = window

    LC_API.on_before_load = () => {
      if (hide) {
        LC_API.hide_chat_window()
      }
    }

    if (LC_API.is_loaded()) {
      if (hide) {
        LC_API.hide_chat_window()
      } else {
        LC_API.minimize_chat_window()
      }
    }
  }
}

export const setupChat = () => {
  window.__lc = window.__lc || {}
  window.__lc.license = 1520
  ;(function() {
    var lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.async = true
    lc.src =
      ('https:' === document.location.protocol ? 'https://' : 'http://') +
      'cdn.livechatinc.com/staging/tracking.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(lc, s)
  })()
}
