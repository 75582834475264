import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'
import { PRODUCT } from '@/utils'

const initialState = {
  isFetching: false,
  isFetched: false,
  error: null,
  products: {
    [PRODUCT.LiveChat]: {
      plan: null,
      hasCard: false,
      subscribed: false,
      daysLeft: 0,
      trial: false,
      active: false,
      cancelledAt: null,
      cardDetails: null,
    },
    [PRODUCT.HelpDesk]: {
      plan: null,
      hasCard: false,
      subscribed: false,
      daysLeft: 0,
      trial: false,
      active: false,
      cancelledAt: null,
      cardDetails: null,
    },
  },
}

export const fetchSubscription = createAsyncActions('subscription/fetch')

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    requestAccessToProduct() {},
  },
  extraReducers: {
    [fetchSubscription.pending]: (state) => {
      state.isFetching = true
      state.isFetched = false
      state.error = null
    },
    [fetchSubscription.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.isFetched = true
      state.products = payload.products
    },
    [fetchSubscription.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.isFetched = true
      state.error = payload?.error
    },
  },
})

export const { requestAccessToProduct } = subscriptionSlice.actions
export const reducer = subscriptionSlice.reducer
