import { createSlice } from '@reduxjs/toolkit'
import { checkDiscount } from '@/utils/products'

const initialState = {
  items: {},
  isFetched: false,
}

/*
  List of services
  (for this moment services are special type of applications with customProps isService set to true)
*/
const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    serviceList(state, { payload }) {
      const items = Object.keys(payload.items).reduce((apps, id) => {
        apps[id] = checkDiscount(payload.items[id])
        return apps
      }, {})

      state.items = items
      state.isFetched = true
    },
    serviceDetails(state, { payload }) {
      state.items = {
        ...state.items,
        [payload.item.id]: checkDiscount(payload.item),
      }
    },
  },
})

export const { serviceList, serviceDetails } = servicesSlice.actions
export const reducer = servicesSlice.reducer
