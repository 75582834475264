import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  hasInitialized: false,
  error: null,
  items: {},
}

export const fetchPublishedApps = createAsyncActions('apps/published')
export const fetchAppInfo = createAsyncActions('apps/fetchInfo')

/*
  List of all published apps so we will always have latest information about the apps
*/
const publishedAppsSlice = createSlice({
  name: 'publishedApps',
  initialState,
  extraReducers: {
    [fetchPublishedApps.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchPublishedApps.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
      state.hasInitialized = true
    },
    [fetchPublishedApps.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
      state.hasInitialized = true
    },
    [fetchAppInfo.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchAppInfo.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = {
        ...state.items,
        [payload?.item?.id]: payload?.item,
      }
    },
    [fetchAppInfo.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const reducer = publishedAppsSlice.reducer
