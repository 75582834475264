export const getSeoTitle = (name, designationLabel) => {
  let seoTitle

  switch (name) {
    case '1&1':
      seoTitle = '1and1 Live Chat'
      break

    case 'BigCommerce':
      seoTitle = 'BigCommerce Live Chat - best widget'
      break

    case 'DotNetNuke':
    case 'Drupal':
    case 'osCommerce':
    case 'PrestaShop':
      seoTitle = `${name} Live Chat - module`
      break

    case 'Dropbox':
      seoTitle = `${name} Live Chat - for business`
      break

    case 'Facebook':
      seoTitle = `${name} Live Chat - customer service`
      break

    case 'Freshdesk':
      seoTitle = `${name} Live Chat - pros and cons`
      break

    case 'GoDaddy':
      seoTitle = `${name} Live Chat - for website`
      break

    case 'Google AdWords':
    case 'Jimdo':
    case 'Shopify':
    case 'Wix':
      seoTitle = `${name} Live Chat - app`
      break

    case 'Google Analytics':
    case 'Highrise':
    case 'Jira Service Desk':
    case 'MailChimp':
    case 'nopCommerce':
    case 'Squarespace':
    case 'SugarCRM':
      seoTitle = `${name} Live Chat - plugin`
      break

    case 'HubSpot':
      seoTitle = `${name} Live Chat - tool`
      break

    case 'Joomla':
    case 'OpenCart':
    case 'WooCommerce':
    case 'WordPress':
      seoTitle = `${name} Live Chat - best plugin`
      break

    case 'Magento':
    case 'Magento 2':
      seoTitle = `${name} Live Chat - extension`
      break

    case 'Traitly':
    case 'Tumblr':
    case 'Volusion':
    case 'Volusion V2':
      seoTitle = `${name} Live Chat - feature`
      break

    case 'Weebly':
    case 'Zendesk':
      seoTitle = `${name} Live Chat - widget`
      break

    case 'WHMCS':
      seoTitle = `${name} Live Chat - addon`
      break

    case 'Slack for LiveChat':
      seoTitle = `Slack for LiveChat`
      break

    default:
      const designationLabelWithSpace =
        designationLabel?.replace(/([A-Z])/g, (match) => ` ${match}`).trim() ||
        'Live Chat'

      seoTitle = `${name} ${designationLabelWithSpace}`
  }

  return `${seoTitle} | ${designationLabel} Integrations`
}

export const getSeoDesc = (id) => {
  const seoDescriptions = {
    '1and1':
      'Place LiveChat on your 1&1 website to engage visitors in real time. This integration will allow you to chat with customers on your 1&1 page within minutes.',
    '3dcart':
      'Use LiveChat on your 3dcart store site to effectively close sales during chats. The integration will allow you to easily add a chat option to your store.',
    activecampaign:
      'Gain new subscribers and improve your follow-up activities. This ActiveCampaign integration will allow you to grow your list using data stored during chats.',
    'adobe-business-catalyst':
      'Add LiveChat to your Adobe Business Catalyst website and exchange data between the two services.',
    amocrm:
      'Add new contacts and leads straight from chat. This AmoCRM integration will allow you to store the customer information you get via LiveChat.',
    autopilot:
      'Integrate LiveChat with Autopilot and automate your email marketing! Add new Contacts to your Journeys straight from a chat.',
    'big-cartel':
      'This integration will allow you to easily reach out to every customer. Add live chat to Big Cartel and improve your sales',
    bigcommerce:
      'LiveChat for BigCommerce will help you turn store visitors into customers. Using the integration, you will be able to easily place LiveChat on your website.',
    botengine:
      'Integrate LiveChat with BotEngine to get a customer service chat bot! Use BotEngine chat platform to create a chatbot for your website',
    'campaign-monitor':
      'Gain newsletter subscribers and easily follow up after chats using the Campaign Monitor integration. It allows you to store contact details of chatters.',
    commercev3:
      'Live chat for CommerceV3. Engage browsing visitors, provide real-time customer service and move them through the purchase process.',
    'constant-contact':
      'Constant Contact: integrate LiveChat with Constant Contact and automate your email marketing! All of that with just a few easy steps.',
    corecommerce:
      'Integrate LiveChat with CoreCommerce to easily sell products online and convert more visitors into customers. The integration only takes a few minutes!',
    'cs-cart':
      'Add LiveChat to your CS-Cart store to save your shopping carts. Solve customer problems in real-time, offer guidance and bring your sales up.',
    'desk-com':
      "Manage your customer support with ease and increase your helpdesk's visibility with Desk.com integration.",
    dropbox:
      'Dropbox integration is a great way to share files with your visitors. It allows you to share files from your Dropbox during chats in LiveChat',
    drupal:
      'Easily place a live chat function on your Drupal website using this integration. The installation process only takes a couple of minutes!',
    ecwid:
      'Chat live with your website visitors. Offer a chat option on your Ecwid store to help your customers in their buying decisions.',
    'ekm-powershop':
      'Empower your EKM shop with LiveChat and increase your sales with pro-active chats. Using the integration, you will have a chat option in minutes!',
    elevio:
      'Keep your communication channels open for your customers using the elevio integration. It places LiveChat and other channels in a handy website widget.',
    facebook:
      'Use our powerful Facebook integration to grow your fan base and get more chats. See how the Facebook integration can help your LiveChat and your customers.',
    freshbooks:
      'Create and manage invoices online with FreshBooks. Your clients will be automatically notified of newly created invoices and encouraged to review them.',
    freshdesk:
      'Integration with Freshdesk. Convert support emails and chat transcripts into tickets in Freshdesk to better support customers.',
    fullcontact:
      'Get additional information on your visitors during chats using FullContact. The information will be displayed directly in your LiveChat.',
    getresponse:
      'Increase your newsletter audience and expand your reach using the GetResponse integration. You can offer subscription to all people who use your live chat.',
    godaddy:
      'Learn how to set up LiveChat on your GoDaddy-based page to answer all the questions coming from your visitors in the real time.',
    'gold-vision':
      'Display Gold-Vision information about your customers straight in your LiveChat window, and create new CRM records from your chats.',
    'google-adwords':
      'Track conversion to chat from Google AdWords campaigns. The integration will provide you with valuable conversion metrics you can use to fine-tune your ads.',
    'google-analytics':
      'Use Google Analytics integration to track impact of website chats on online sales and gather valuable marketing data.',
    'google-plus':
      'Grow your Google+ profile by adding a +1 button to your chat window. Visitors who like your service can +1 it during a conversation.',
    'google-tag-manager':
      'Add LiveChat to all your sites easily using Google Tag Manager. The integration allows you to place LiveChat on a within minutes.',
    helpmonks:
      'LiveChat ? Helpmonks: integrate LiveChat with Helpmonks and keep all of your communication in one place! All of that with just a few easy steps.',
    highrise:
      'Highrise will make your sales efforts easier and more efficient. Centralize your sales information using this CRM solution.',
    hubspot:
      'With the LiveChat integration for HubSpot, you will be able to add new contacts straight from chat which will make your sales efforts much easier.',
    icontact:
      'Gain a larger newsletter audience and simplify your follow-up activities using the iContact app. It allows you to gather subscribers before each chat.',
    infusionsoft:
      'Infusionsoft and LiveChat integration allows to easily connect chat transcripts with contacts in Infusionsoft CRM. Integrate Infusionsoft CRM with LiveChat!',
    instapage:
      'Integrate LiveChat with Instapage and use it to close more deals and provide your customers with additional info about your products!',
    'iris-crm': 'Chat live with your online visitors through IRIS CRM.',
    jimdo:
      'Place LiveChat on your Jimdo website. Talk with your visitors and answer their questions quickly. It will increase satisfaction of your customers.',
    'join-me':
      "Use join.me to remotely access your visitor's screen and easily resolve support cases.",
    joomla:
      'Place a live chat function on your Joomla!-powered website with a few simple clicks. The installation will take just a couple of minutes.',
    kissmetrics:
      'Log LiveChat-related data in your Kissmetrics to learn more about your customer service activities and store lead information.',
    landingi:
      'Start engaging with your Landingi visitors and increasing conversion rates.',
    'leadpages-live-chat':
      'Answer questions in real-time and convert more with live chat for Leadpages.',
    leadsquared:
      "Integrate LiveChat with LeadSquared and and don't miss out any of your customers! Use the integration to gain new leads and close more deals.",
    lemonstand: 'Sell more on your LemonStand website with LiveChat.',
    'live-chat-for-shoplo':
      'Assist customers through the purchase with live chat for Shoplo.',
    'livechat-knowledge-base':
      'LiveChat Knowledge Base lets you access all your help docs when chatting to customers without navigating to separate pages.',
    magento:
      "With this integration you'll be able to set LiveChat in your Magento 2.0 store and to track information about your visitors' purchases.",
    mailchimp:
      'MailChimp integration will grow your newsletter subscriber base and make it easy to follow up after a chat.',
    median:
      'LiveChat - Median: start a CoBrowse session with your customers straight during a chat! All of that with just a few easy steps.',
    'microsoft-dynamics':
      'This integration allows you both store new contacts directly from chat as well as display existing customer data in the application.',
    miva: 'Do away with cart abandonment for good. Add LiveChat to your Miva store, provide continuous assistance and help visitors complete the purchasing process.',
    mixpanel:
      'Monitor LiveChat events on your Mixpanel account. This integration allows you to store events like starting a chat, filling out a pre-chat survey and more.',
    nutshell:
      'Display customer data from Nutshell in LiveChat. The integration will automatically bring up customer information during chats.',
    opencart:
      'Integrate LiveChat with your OpenCart and start closing more deals by targeting the exact needs of your customers! All of that with just a few easy steps.',
    oscommerce:
      'LiveChat osCommerce integration allows you to add chat to your website. Use a personal touch and amaze your customers, closing more deals in a process!',
    'osi-affiliate-software':
      'Stay in touch with your affiliate program partners and contact them using chat.',
    pagecloud:
      'Start providing live chat customer support to your PageCloud visitors and build customer satisfaction.',
    pinnaclecart:
      'Add LiveChat to your PinnacleCart store and start reaching customers.',
    pipedrive:
      'Our Pipedrive LiveChat integration will allow you to easily manage your Contacts and Deals straight from the chat! All of that with just a few simple steps.',
    playvox:
      'Integrate LiveChat with PlayVox and measure the quality of your chats in enjoyable, hustle-free way!',
    prestashop:
      'Integrate PrestaShop with LiveChat to boost your online sales using chats with customers. The integration will help you place chat on your store in minutes.',
    'proprofs-knowledgebase':
      "Place LiveChat solution on your ProProfs Knowledgebase site. Users who can't find a solution there will be able to ask your agents for help.",
    salesforce2:
      'Salesforce integration enables LiveChat agents to pass details about clients as Contacts or Leads, and to forward chat transcripts as Cases.',
    segment:
      'Easily place a live chat function on your website or mobile application using the Segment app.',
    'shopify-live-chat':
      'Add live chat function in your Shopify store using this integration. You will be chatting with your customers in no time. Try chat for Shopify for free!',
    site123:
      'Integrate LiveChat with SITE123 and turn leads into sales. Make sure that your customers will get quick answers to their questions.',
    'spark-pay':
      'Get more chances to convert website visitors into customers using LiveChat! Upgrade your Spark Pay store with a live chat function to engage your customers.',
    squarespace:
      'Make your Squarespace Live Chat chat a reality and show your customers how exceptional a Customer Service can be, closing more deals in a process!',
    stratifyd:
      'Integrate LiveChat with Stratifyd and analyse the information about your customers with just a few easy steps and AI-powered analytics from Stratifyd.',
    sugarcrm:
      'Use SugarCRM to access your contacts and manage sales tasks directly from your live chat window.',
    talkdesk:
      'Keep communication with customers and contact information in one place. The integration will allow you to quickly look up customer information in LiveChat.',
    teamviewer:
      "Access and control your visitors' desktops remotely using the TeamViewer app to easily solve tougher customer service cases.",
    triggerbee:
      'Triggerbee: integrate LiveChat with Triggerbee and start analyzing the way in which customers interact with your Support!',
    tumblr:
      'Add LiveChat to your Tumblr website. Give your visitors opportunity to easily contact you. Boost your sales and provide the best customer service!',
    twitter:
      'Get more followers and mange your Twitter notifications in LiveChat. The integration will allow you to place a Twitter icon in the chat window.',
    unbounce:
      'Engage visitors on your Unbounce landing page, sell more and provide immediate customers service using LiveChat.',
    volusion:
      'Make your Volusion Chat come true with just a few easy steps. Integrate LiveChat with your store and see how easy it is to close more deals!',
    webflow:
      'Add LiveChat to your Webflow website to answer all sales and support questions coming from your online visitors in real time.',
    weebly:
      'Start chatting with your Weebly website visitors. Offer live chat on Weebly website to convert visitors into customers through chat customer service.',
    whatconverts:
      'Integrate LiveChat with WhatConverts and use their tracking tools to determine which traffic source generate more leads!',
    whmcs:
      'Chat with visitors on your WHMCS site and convert them into customers. The installation will only take a couple minutes.',
    wishpond:
      'Wishpond Live Chat integration will improve your support and boost your sales. Add this to your website and watch results from day one',
    woocommerce:
      'Add LiveChat to your WooCommerce shop and reach out to your online shop customers.',
    woopra:
      'Track LiveChat data and affect the way it works using Woopra. For example, you can choose when to show the chat window.',
    wordpress:
      'Use this integration to place a live chat function on your Wordpress website. All it takes is just a couple of clicks!',
    'x-cart':
      'Grow your business with LiveChat for X-Cart. Integrate both tools with just a few clicks to start chatting with prospective customers.',
    zapier:
      'Looking for more integrations? Check out LiveChat integrations on Zapier.',
    'zen-cart':
      'This integration makes it easy to install a live chat function on your Zen Cart store. You will be chatting with customers within minutes!',
    zendesk:
      'Integrate Zendesk with LiveChat to create tickets directly from chat. The integration also allows you to look up tickets cases in LiveChat.',
  }

  return seoDescriptions[id] || null
}
