import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  error: null,
  items: [],
}

export const fetchResources = createAsyncActions('apps/resources')

/*
  List of applications resources like in-app-upgrades, etc
*/
const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  extraReducers: {
    [fetchResources.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchResources.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
    },
    [fetchResources.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const reducer = resourcesSlice.reducer
