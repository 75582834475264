import { parse, isWithinRange } from 'date-fns'
import { PAYMENT } from '@/constants'

export const formatPrice = (pence, alwaysDecimal = false) => {
  const price = (pence / 100).toFixed(2)

  return price % 1 > 0 || alwaysDecimal ? price : parseInt(price, 10)
}

export const getDollarPrice = (value) => {
  return '$' + formatPrice(value, true)
}

export const checkDiscount = (app) => {
  const customProps = app.customProps

  if (customProps) {
    const { discountFrom, discountTo, discountPercent } = customProps

    if (discountFrom && discountTo && discountPercent) {
      const percent = parseInt(discountPercent, 10)
      const today = new Date()

      if (
        isWithinRange(
          parse(today.toISOString()),
          parse(discountFrom),
          parse(discountTo)
        ) &&
        !isNaN(percent) &&
        percent >= 0 &&
        percent < 100
      ) {
        const payment = {
          ...app.payment,
          price: app.payment.price - (percent / 100) * app.payment.price,
          oldPrice: app.payment.price,
          discountPercent: parseInt(discountPercent, 10),
        }

        return {
          ...app,
          payment,
        }
      }
    }
  }

  return app
}

export const getExternalChargePaymentInfo = (charge = {}, frequency, taxes) => {
  const payment = {
    creditCardRequired: true,
    frequency,
  }

  if (charge.hasOwnProperty('per_account')) {
    payment.perAccount = charge.per_account
  }

  if (charge.hasOwnProperty('trial_days')) {
    payment.trialDays = charge.trial_days
  }

  if (charge.hasOwnProperty('price')) {
    payment.price = charge.price
  }

  if (
    charge.hasOwnProperty('frequency') &&
    frequency !== PAYMENT.DIRECT_CHARGE
  ) {
    payment.frequency = charge.frequency
  }

  if (frequency === PAYMENT.DIRECT_CHARGE) {
    payment.frequency = PAYMENT.DIRECT_CHARGE
  }

  // TODO: include credit card required in the charge from billing api
  if (charge.hasOwnProperty('credit_card_required')) {
    payment.creditCardRequired = charge.creditCardRequired
  }

  if (taxes) {
    const { tax_rate, tax_region, tax_value, total_price } = taxes

    payment.taxes = {
      tax_rate,
      tax_region,
      tax_value,
      total_price,
    }
  }

  return payment
}
