import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  items: [],
  error: null,
}

export const fetchCampaigns = createAsyncActions('campaigns/loggedIn/fetch')
export const fetchPublicCampaigns = createAsyncActions('campaigns/public/fetch')

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  extraReducers: {
    [fetchCampaigns.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchCampaigns.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
    },
    [fetchCampaigns.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [fetchPublicCampaigns.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchPublicCampaigns.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
    },
    [fetchPublicCampaigns.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const reducer = campaignsSlice.reducer
