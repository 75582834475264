/* eslint-disable import/no-anonymous-default-export */
import { createApiRequest } from '@/utils/api'
import { HttpMethod } from '@/constants/utils'

import type { IApplication } from './interfaces'

const readAllInReview = () =>
  createApiRequest({ method: HttpMethod.GET, path: '/applications/reviewed' })

const installApp = (id: string) =>
  createApiRequest({
    method: HttpMethod.PUT,
    path: `/applications/${id}/install`,
  })

const getAppInfo = (id: string) =>
  createApiRequest<IApplication>({
    method: HttpMethod.GET,
    path: `/applications/${id}/published`,
  })

const getPublishedApps = () =>
  createApiRequest({
    method: HttpMethod.GET,
    path: `/applications/published`,
    noauth: true,
  })

const getAppRates = () =>
  createApiRequest({
    method: HttpMethod.GET,
    path: `/feedback/rate/application`,
    noauth: true,
  })

const getFeedbackSummary = (id: string) =>
  createApiRequest({
    method: HttpMethod.GET,
    path: `/feedback/rate/application/${id}`,
    noauth: true,
  })

const rateApp = (id: string, body: any) =>
  createApiRequest({
    method: HttpMethod.PUT,
    path: `/feedback/rate/application/${id}`,
    body,
  })

const getResources = (id: string) =>
  createApiRequest({
    method: HttpMethod.GET,
    path: `/applications/${id}/resources`,
    noauth: true,
  })

export default {
  readAllInReview,
  installApp,
  getAppInfo,
  getResources,
  getPublishedApps,
  getAppRates,
  getFeedbackSummary,
  rateApp,
}
