export const openPopup = ({
  url,
  winName,
  width = 500,
  height = 700,
  scroll = true,
  onClose = undefined,
  openInNewTab = undefined,
}) => {
  const leftPosition = window.screen.width
    ? Number(window.screen.width - width) / 2
    : 0

  const topPosition = window.screen.height
    ? Number(window.screen.height - height) / 2
    : 0

  const settings =
    'height=' +
    height +
    ',width=' +
    width +
    ',top=' +
    topPosition +
    ',left=' +
    leftPosition +
    ',scrollbars=' +
    scroll +
    ',resizable'

  const popup = openInNewTab
    ? window.open(url)
    : window.open(url, winName, settings)

  if (onClose) {
    const timer = setInterval(function () {
      if (popup && popup.closed) {
        clearInterval(timer)
        onClose()
      }
    }, 1000)
  }

  return popup
}
