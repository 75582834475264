import React from 'react'
import ReduxProvider from './redux'
import ThemeProvider from './theme'
import AlgoliaProvider from './algolia'
import HydrationProvider from './hydration'

export const wrapWithProviders = ({ element }) => (
  <HydrationProvider>
    <ReduxProvider>
      <AlgoliaProvider>
        <ThemeProvider>{element}</ThemeProvider>
      </AlgoliaProvider>
    </ReduxProvider>
  </HydrationProvider>
)
