export const PUT = 'PUT'
export const PATCH = 'PATCH'
export const POST = 'POST'
export const GET = 'GET'
export const DELETE = 'DELETE'

export enum HttpMethod {
  PUT = 'PUT',
  PATCH = 'PATCH',
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
}

export const OK = 200
export const CREATED = 201
export const NO_CONTENT = 204
export const UNAUTHORIZED = 401
export const NOT_FOUND = 404
export const BAD_REQUEST = 409

export const SCOPES = {
  BILLING_MANAGE: 'billing_manage',
  BILLING_ADMIN: 'billing_admin',
  BILLING_ALL: 'billing--all:rw',
  SUBSCRIPTION_MANAGE: 'subscription_manage',
  INTEGRATIONS_MANAGE: 'integrations_manage',
  HD_INTEGRATIONS_MANAGE: 'hd_integrations_manage',
  ORGANIZATION_MANAGE: 'organization--my:rw',
  PRODUCT_ORGANIZATION_MANAGE: 'organization.product--my:rw',
  DEV_PLATFORM_ADMIN: 'dev_platform_admin',
}

export const KEY_CODES = {
  ENTER: 13,
  TAB: 9,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
}

export const REGION = {
  FRA: 'fra',
  FRA_A: 'fra-a',
  FRA_B: 'fra-b',
  DAL: 'dal',
}

export const APP_WIDGET_PLACEMENT = {
  PLUGIN: 'plugin',
  SETTINGS: 'settings',
  FULL_SCREEN: 'fullscreen',
  MESSAGE_BOX: 'messagebox',
  TARGETED_MESSAGE: 'targeted-message',
}

export const PRODUCT_STATUS = {
  IN_DEVELOPMENT: 'IN_DEVELOPMENT',
  IN_REVIEW: 'IN_REVIEW',
  ACCEPTED: 'ACCEPTED',
  PUBLISHED: 'PUBLISHED',
}

export const AUTH_TYPE = {
  iframe: 'iframe',
  popup: 'popup',
  redirect: 'redirect',
}
