import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  hasInitialized: false,
  error: null,
  items: {},
}

export const fetchPrivateApps = createAsyncActions('apps/private')

/*
  List of applications created by currently logged in user
*/
const privateAppsSlice = createSlice({
  name: 'apps',
  initialState,
  extraReducers: {
    [fetchPrivateApps.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchPrivateApps.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = payload.items
      state.hasInitialized = true
    },
    [fetchPrivateApps.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
      state.hasInitialized = true
    },
  },
})

export const reducer = privateAppsSlice.reducer
