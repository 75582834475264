import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

const initialState = {
  isFetching: false,
  error: null,
  items: {},
}

export const fetchDeveloper = createAsyncActions('developers/fetch')

const developersSlice = createSlice({
  name: 'developers',
  initialState,
  extraReducers: {
    [fetchDeveloper.pending]: (state) => {
      state.isFetching = true
      state.error = null
    },
    [fetchDeveloper.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items = {
        ...state.items,
        [payload.item.id]: payload.item,
      }
    },
    [fetchDeveloper.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
  },
})

export const reducer = developersSlice.reducer
