import { createSlice } from '@reduxjs/toolkit'
import { createAsyncActions } from '@/utils/sagas'

export const initialState = {
  items: [],
  isFetching: false,
  error: null,
}

export const readRecurrentCharge = createAsyncActions('recurrentCharge/read')
export const createRecurrentCharge = createAsyncActions(
  'recurrentCharge/create'
)
export const activateRecurrentCharge = createAsyncActions(
  'recurrentCharge/activate'
)
export const acceptRecurrentCharge = createAsyncActions(
  'recurrentCharge/accept'
)
export const cancelRecurrentCharge = createAsyncActions(
  'recurrentCharge/cancel'
)
export const declineRecurrentCharge = createAsyncActions(
  'recurrentCharge/decline'
)

const recurrentChargesSlice = createSlice({
  name: 'recurrentCharge',
  initialState,
  reducers: {
    recurrentChargePollStart(state) {
      state.isFetching = true
    },
    recurrentChargePollStop(state) {
      state.isFetching = false
    },
    archiveRecurrentCharge(state, { payload }) {
      const itemId = payload?.json?.id || payload?.item?.id
      state.items = state.items.filter((item) => item.id !== itemId)
    },
  },
  extraReducers: {
    [acceptRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [acceptRecurrentCharge.fulfilled]: (state) => {
      state.isFetching = false
    },
    [acceptRecurrentCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [declineRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [declineRecurrentCharge.fulfilled]: (state) => {
      state.isFetching = false
    },
    [declineRecurrentCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [cancelRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [cancelRecurrentCharge.fulfilled]: (state, { payload }) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === payload.item.id
      )
      state.items[itemIndex] = payload.item
      state.isFetching = false
    },
    [cancelRecurrentCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [activateRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [activateRecurrentCharge.fulfilled]: (state, { payload }) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === payload?.item?.id
      )

      state.isFetching = false
      state.items[itemIndex] = payload.item
    },
    [activateRecurrentCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [createRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [createRecurrentCharge.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items.push(payload?.item)
    },
    [createRecurrentCharge.rejected]: (state, { payload }) => {
      state.isFetching = false
      state.error = payload?.error
    },
    [readRecurrentCharge.pending]: (state) => {
      state.isFetching = true
    },
    [readRecurrentCharge.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.items.push(payload?.item)
    },
    [readRecurrentCharge.rejected]: (state) => {
      state.isFetching = false
    },
  },
})

export const {
  recurrentChargePollStart,
  recurrentChargePollStop,
  archiveRecurrentCharge,
} = recurrentChargesSlice.actions
export const reducer = recurrentChargesSlice.reducer
