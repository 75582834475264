import { call, put, takeEvery } from 'redux-saga/effects'

import { fetchResources } from '@/store/resources'
import appsApi from '@/apis/apps'
import { OK } from '@/constants/utils'

function* requestResourcesList({ payload: { id } }) {
  try {
    const { status, json } = yield call(appsApi.getResources, id)

    if (status === OK) {
      yield put(
        fetchResources.fulfilled({
          items: json,
        })
      )
    } else {
      throw new Error("App resources couldn't be loaded")
    }
  } catch (error) {
    yield put(fetchResources.rejected({ error: error?.message }))
  }
}

export function* watchResourcesRequests() {
  yield takeEvery(fetchResources.pending, requestResourcesList)
}
