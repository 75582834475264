import * as Sentry from '@sentry/react'
import { select, put } from 'redux-saga/effects'

import { NOTIFICATIONS } from '@/constants'
import { UNAUTHORIZED } from '@/constants/utils'
import { notificationsCreate } from '@/store/notifications'
import {
  getUserData,
  getUserPermissions,
  isAuthorized,
} from '@/store/selectors'
import { isAdBlockEnabled } from '@/utils/browser'

export function* errorHandler(error, tag) {
  const isUserAuthorized = yield select(isAuthorized)
  const user = yield select(getUserData)
  const userPermissions = yield select(getUserPermissions)

  // Simple handler for unauthorized sagas calls
  if (error?.status === UNAUTHORIZED) {
    yield put(
      notificationsCreate({
        variant: NOTIFICATIONS.VARIANTS.ERROR,
        content: `We couldn't authorize your request as you've been logged out. Log in and try again.`,
        autoHideDelayTime: NOTIFICATIONS.AUTO_HIDE_DELAY_TIME,
        type: NOTIFICATIONS.TYPES.PAGE_LOAD,
      })
    )

    window.location = process.env.GATSBY_PATH_PREFIX || '/'
  }

  Sentry.withScope(function(scope) {
    scope.setTag('type', tag)

    if (isUserAuthorized) {
      scope.setUser({
        login: user?.email || '',
        name: user?.name || '',
        organization_id: user?.organizationId || '',
        permission: userPermissions?.join(',') || '',
      })
    }

    if (error.data) {
      scope.setExtra('data', error.data)
      scope.setExtra('status', error.status)
    }

    Sentry.captureException(error)
  })
}

export class SagaError extends Error {
  constructor(message, data, status) {
    super(message)

    if (isAdBlockEnabled() && !status) {
      this.adblockDetected = true
    }

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SagaError)
    }

    this.name = 'SagaError'
    this.data = data
    this.status = status
  }
}
