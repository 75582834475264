import { createSelector } from 'reselect'
import find from 'lodash/find'

import type { IApplication } from '@/apis/apps/interfaces'
import type { TPublishedApplications } from './publishedApps/interfaces'
import type { ICampaign } from './campaigns/interfaces'
import type { ICart } from './cart/interfaces'
import type { RootState } from '.'

import {
  filterAppsForLicense,
  filterInstalledOnly,
  filterOutServices,
  normalizeItemProductType,
} from '@/utils/products'
import { DEFAULT_PROTOCOL_VERSION } from '@/constants'

const getCart = (state: RootState): ICart => state.cart
const getCartSummary = (state: RootState) => getCart(state)?.summary
const getCampaigns = (state: RootState): ICampaign[] => {
  return state.campaigns.items || []
}

const publishedApps = (state: RootState): TPublishedApplications =>
  state.publishedApps.items
const getInstalledAppsIds = (state: RootState): string[] =>
  state.installedApps.items
const privateApps = (
  state: RootState
): Record<IApplication['id'], IApplication> => state.apps.items
const inReviewApps = (
  state: RootState
): Record<IApplication['id'], IApplication> | Record<string, never> =>
  state.inReviewApps.items
const services = (state: RootState): Record<IApplication['id'], IApplication> =>
  state.services.items || {}

const getAppInReview = (
  state: RootState,
  id: string
): IApplication | Record<string, never> => {
  const items = inReviewApps(state)

  return items[id] || {}
}

const getServiceBySlug = (
  state: RootState,
  slug?: string
): IApplication | Record<string, never> => {
  const items = services(state)
  return find(items, (item) => item.slug === slug) || {}
}
const getService = (state: RootState, id: string) => {
  const items = services(state)

  return items[id] || {}
}

const getServiceByName = (
  state: RootState,
  name: string
): IApplication | Record<string, never> => {
  const items = services(state)
  return find(items, (item) => item.name === name) || {}
}
const getPublishedAppBySlug = (
  state: RootState,
  slug?: string
): IApplication | Record<string, never> => {
  const items = publishedApps(state)
  return (
    normalizeItemProductType(find(items, (item) => item.slug === slug)) || {}
  )
}

const getPublishedAppByName = (
  state: RootState,
  name: string
): IApplication | Record<string, never> => {
  const items = publishedApps(state)
  return find(items, (item) => item.name === name) || {}
}

const getPublishedApp = (
  state: RootState,
  id: string
): IApplication | Record<string, never> => {
  const items = publishedApps(state)

  if (items[id]) {
    return items[id]
  } else {
    // This is an extra case for apps in review to be able to have the same functionality as published app
    // TODO: Refactor InstallButton component to smaller ones
    const itemInReview = getAppInReview(state, id)

    if (itemInReview) {
      return itemInReview
    } else {
      return {}
    }
  }
}

export const getItemBySlug: (
  state: RootState,
  slug?: string
) => IApplication | Record<string, never> = createSelector(
  [getServiceBySlug, getPublishedAppBySlug],
  (service, app) => {
    if (app.id) return app
    else if (service.id) return service
    else return {}
  }
)

export const getItemByName: (
  state: RootState,
  name: string
) => IApplication | Record<string, never> = createSelector(
  [getServiceByName, getPublishedAppByName],
  (service, app) => {
    if (app.id) return app
    else if (service.id) return service
    else return {}
  }
)

export const getItemById: (
  state: RootState,
  id: string
) => IApplication | Record<string, never> = createSelector(
  [getService, getPublishedApp],
  (service, app) => {
    if (app.id) return app
    else if (service.id) return service
    else return {}
  }
)

export const getInstalledApps: (state: RootState) => IApplication[] =
  createSelector(
    [publishedApps, getInstalledAppsIds],
    (publishedApps, installedApps) => {
      const allApps = Object.keys(publishedApps).map((id) => publishedApps[id])

      if (!allApps.length && !installedApps.length) return []

      return allApps
        .filter(filterInstalledOnly(true, installedApps))
        .filter(filterOutServices)
    }
  )

export const getPrivateApps: (state: RootState) => IApplication[] =
  createSelector([privateApps], (privateApps) => {
    const allApps = Object.keys(privateApps).map((id) => privateApps[id])

    if (!allApps.length) return []

    return allApps
  })

export const getInReviewApps: (state: RootState) => IApplication[] =
  createSelector([inReviewApps], (inReviewApps) => {
    const allApps = Object.keys(inReviewApps).map((id) => inReviewApps[id])

    return allApps.length > 0 ? allApps : []
  })

export const getPublishedApps: (state: RootState) => IApplication[] =
  createSelector([publishedApps], (publishedApps) => {
    const allApps = Object.keys(publishedApps).map((id) => publishedApps[id])

    return filterAppsForLicense(allApps, [], DEFAULT_PROTOCOL_VERSION)
  })

export const getCampaignAppsById: (
  state: RootState,
  id: string
) => IApplication[] = createSelector(
  [getCampaigns, publishedApps, (state, id) => id],
  (campaigns: ICampaign[], apps: TPublishedApplications, id) => {
    const campaign = campaigns.filter((campaign) => campaign.id === id)[0]
    const campaignApps = campaign?.applications || []
    const campaignAppsIds = campaignApps.map((app) => app.id)
    return campaignAppsIds
      .filter((appId) => apps[appId])
      .map((appId) => apps[appId])
  }
)

export const getCartSummaryProducts = (state: RootState) => {
  const cartSummary = getCartSummary(state)
  const cartSummaryItems = [
    ...(cartSummary?.oneTime || []),
    ...(cartSummary?.recurrent || []),
  ]

  return cartSummaryItems
}
